import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../Component/ButtonComp/ButtonComp";
import Loader from "../util/Loader";

const PageNotFound = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handlenavigate = () => {
    navigate("/");
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  },[]);

  return (
    loading? <Loader pagename="Page Not Found..." /> : ""
    // <section className="container404 mt-3">
    //   <div className="center">
    //     <div className="block text-center">
    //       <h1 className="error-code">404</h1>
    //       <h3>Page Not Found</h3>
    //       <p className="error-message">
    //         The link you clicked may be broken or the
    //         <br />
    //         page may have been removed.
    //       </p>

    //       <ButtonComp
    //         wrapperName="submit_btn_wrapper"
    //         type="submit"
    //         btnStyle="box"
    //         btnText={"visit homepage"}
    //         disabled={false}
    //         onClick={() => handlenavigate()}
    //       />
    //     </div>
    //   </div>
    // </section>
 
  );
};

export default PageNotFound;
