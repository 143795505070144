import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccess, getLoginType } from "../../util/Authenticate";
import { decryptData, encrypt } from "../../util/Authenticate/CryptoJS";

export default function DashboardLandingPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (decryptData(getAccess())) {
      let LoginType = decryptData(getLoginType());
      switch (LoginType) {
        case "client":
          navigate(`/${encrypt("ClientDashboard")}`);
          break;
        case "relationship_manager":
          navigate(`/${encrypt("Rmdashboard")}`);
          break;
        case "operation_manager":
          navigate(`/${encrypt("Operationdashboard")}`);
          break;
        case "super_admin":
          navigate(`/${encrypt("Defaultdashboard")}`);
          break;
        default:
          navigate(`/${encrypt("Defaultdashboard")}`);
          break;
      }
    } else {
      navigate("/");
    }
  }, [getAccess()]);

  return null;
}
