import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dribbble_1img from "../../assets/img/dribbble_1.gif";
import ButtonComp from "../../Component/ButtonComp/ButtonComp";
import Loader from "./../Loader";
import { decryptData, encrypt, encryptData } from "./CryptoJS";
import { setLocalStorage } from "./index";
export default function Redirectslogout() {
  const temroles = decryptData(useParams().roles);
  const temaction = decryptData(useParams().action);
  const [stateHandle, setstateHandle] = useState(false);
  const navigate = useNavigate();
  // Define the logout function
  // Define constants for roles and actions

  const Handlenavigate = useCallback(() => {
    switch (temroles) {
      case "client":
        navigate("/login");
        break;
      case "relationship_manager":
        navigate("/login/rm");
        break;
      case "operation_manager":
        navigate("/login/rm");
        break;
      case "super_admin":
        navigate("/login/rm");
        break;
      default:
        navigate("/login");
        break;
    }
    setLocalStorage(encrypt("counter"), "2");
    setLocalStorage(encrypt("jwt-access"), encryptData("false"));
    window.location.reload(true);
  }, [temroles]);

  useEffect(() => {
    if (temaction === "user") {
      setstateHandle(false);
      Handlenavigate();
    }
    return () => {
      if (temaction === "system" || temaction == "Bad_credentials") {
        setstateHandle(true);
      }
    };
  }, [temaction]);
  return (
    <>
      {stateHandle ? (
        <div className="w3jumbo">
          <div>
            <img className="img-fluid" src={dribbble_1img}></img>
            <h1 className="">
              <code>Access Denied</code>
            </h1>
            <h3>You dont have permission to view this site.</h3>
            <h3 className="">🚫🚫🚫🚫</h3>
            <h6 className="">error code:403 forbidden</h6>

            <div>
              <ButtonComp
                wrapperName="submit_btn_wrapper"
                type="submit"
                btnStyle="box"
                btnText={"Go Back"}
                onClick={() => Handlenavigate()}
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader pagename="Dashboard " />
      )}
    </>
  );
}
