import { faClose, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Apiurl } from "../../util/apiurl";
import axiosInstance from "../../util/axiosInstance";
import Loader from "../../util/Loader";
import StockAnalysisPage from "./StockAnalysisPage";
import "./WatchListModal.scss";

const WatchListPage = ({ userId }) => {
  const MAX_WATCHLISTS = 5; // Maximum allowed watchlists
  const [loading, setLoading] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [watchlist, setWatchlist] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedStocktype, setSelectedStocktype] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [page, setPage] = useState(1); // Pagination for API calls
  const [hasMore, setHasMore] = useState(true); // Check if more data exists
  const inputRef = useRef();
  const dropdownRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [watchlistold, setWatchlistOld] = useState([]);
  // Function to fetch the watchlist data from the API
  const fetchWatchlistData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${Apiurl.getAllWatchlist}`);
      const watchListData = response.data;

      if (watchListData && watchListData.length > 0) {
        return watchListData;
      }
      setLoading(false);
      return [];
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the watchlist data when the component mounts
  useEffect(() => {
    const getWatchlistData = async () => {
      const data = await fetchWatchlistData();
      if (data.length > 0) {
        setWatchlistOld(data);
        getHighestWatchlistNumber(data); // Get the highest watchlist number and start creating buttons from there
      } else {
        createWatchlists(0); // Default to 1 watchlist button if no data
      }
    };

    getWatchlistData();
  }, []);

  // Function to get the highest watchlist number from the current buttons
  const getHighestWatchlistNumber = (data) => {
    // Get the highest watchlist number (e.g., WATCHLIST1, WATCHLIST2, etc.)
    const highestWatchlist = Math.max(
      ...data.map((item) =>
        parseInt(item.watchlistEnum.replace("WATCHLIST", ""))
      ),
      0 // Default to 0 if no data
    );
    console.log("creating", highestWatchlist);
    setActiveButtonIndex(highestWatchlist > 0 ? highestWatchlist - 1 : 0); // Set the active button index
    createWatchlists(highestWatchlist); // Start creating buttons from the next number
    fetchWatchlist(highestWatchlist > 0 ? highestWatchlist - 1 : 0);
    return highestWatchlist;
  };

  // Function to create new watchlist buttons
  const createWatchlists = (startingIndex) => {
    // Ensure we do not exceed the maximum allowed watchlists
    if (buttons.length < MAX_WATCHLISTS) {
      const nextWatchlistIndex = startingIndex; // Increment the index to get the next watchlist number

      if (nextWatchlistIndex <= MAX_WATCHLISTS) {
        let newWatchlistBtn = {
          label: `Watchlist ${startingIndex}`, // Correct string template
          id: nextWatchlistIndex, // Ensure unique id
        };

        // Add new button to the state, keeping previous buttons
        setButtons((prevData) => [...prevData, newWatchlistBtn]);

        console.log("New Watchlist Buttons:", newWatchlistBtn);
      }
    } else {
      console.log(`Cannot add more than ${MAX_WATCHLISTS} watchlist buttons.`);
    }
  };

  // Handle "Add" button click event
  const handleAddButtonClick = () => {
    const currentWatchlistCount = buttons.length;
    // Only add a new button if the number of buttons is less than the max
    if (currentWatchlistCount < MAX_WATCHLISTS) {
      createWatchlists(currentWatchlistCount); // Create the next button
    } else {
      console.log("Maximum number of watchlists reached.");
    }
  };
  const handleButtonClick = useCallback((index) => {
    setActiveButtonIndex(index);
    fetchWatchlist(index);
  }, []);

  const fetchWatchlist = async (index) => {
    setLoading(true);
    setWatchlist([]);
    try {
      const response = await axiosInstance.get(
        `${Apiurl.getWatchlistbyid}` + `WATCHLIST` + `${index + 1}`
      );
      const watchListData = response.data;
      setWatchlist([]);

      Object.values(watchListData).map((item) => {
        let SingleData = {
          id: item.id,
          stockName: item?.mfShortDescription || item?.stockName,
          type: item.type,
        };
        setWatchlist((prevData) => [...prevData, SingleData]);
      });
      setLoading(false);
    } catch (error) {
      console.error("Login error: ", error);

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = useCallback(
    (e) => {
      // fetchWatchlistSearch(e.target.value);
      const value = e.target.value;
      setSearchTerm(value);
      if (value.length > 2) {
        // Reset everything when search term changes
        setSuggestions([]);
        setPage(1);
        setHasMore(true);
        fetchSuggestions(value, 1);
      }
    },
    [searchTerm]
  );
  // API call to fetch suggestions
  const fetchSuggestions = async (query, page) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.watchListSearch}` + `${query}`
      );
      const watchListData = response.data;
      setSuggestions(watchListData);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    const bottom =
      dropdownRef.current.scrollHeight ===
      dropdownRef.current.scrollTop + dropdownRef.current.clientHeight;

    if (bottom && !loading && hasMore) {
      setPage((prev) => prev + 1);
      fetchSuggestions(searchTerm, page + 1);
    }
  };

  const handleClickStock = useCallback((value, type) => {
    setSelectedStock(value);
    setSelectedStocktype(type);
  }, []);

  const handleDelete = () => {
    setSelectedStock(null);
    setSearchTerm("");
    fetchWatchlist(activeButtonIndex);
    fetchWatchlistData();
  };
  return (
    <>
      {!loading ? (
        <>
          {selectedStock ? (
            <StockAnalysisPage
              stock={selectedStock}
              id={userId}
              Transactionstype={selectedStocktype}
              watchData={`WATCHLIST` + (activeButtonIndex + 1)}
              watchlistold={watchlistold}
              handleClose={handleDelete}
            />
          ) : (
            <div className="row">
              <div className="col-12">
                {" "}
                <div className="tabs ">
                  {buttons.map((btn, index) => (
                    <button
                      key={btn.id}
                      // onClick={() => setActiveButtonIndex(index)}
                      onClick={() => handleButtonClick(index)}
                      className={
                        activeButtonIndex === index
                          ? "tabactive tab-button"
                          : "tab-button"
                      }
                    >
                      Watchlist {index + 1}
                    </button>
                  ))}
                  <button
                    className={
                      buttons.length === 5
                        ? "box_btn btn add-button"
                        : "box_btn btn add-button"
                    }
                    onClick={handleAddButtonClick}
                    disabled={buttons.length >= MAX_WATCHLISTS}
                  >
                    Add More
                  </button>
                </div>
                <div className="search-bar-stock">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  <input
                    type="text"
                    ref={inputRef}
                    value={searchTerm}
                    placeholder={"Search for items..."}
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                    className="search-input"
                  />
                  <button className="filter-button">
                    <FontAwesomeIcon icon={faClose} />
                  </button>
                </div>
                {searchTerm && (
                  <div
                    className="suggestions-dropdown"
                    ref={dropdownRef}
                    onScroll={handleScroll}
                  >
                    {suggestions.length > 0 ? (
                      <>
                        {suggestions.map((stock, index) => (
                          <button
                            key={index}
                            className="listsearch_ul my-2 btn"
                            onClick={() =>
                              handleClickStock({ ...stock }, "search")
                            }
                          >
                            <span className="listsearch_ul_stockname">
                              {stock?.shortSchemeDescrip || stock?.stockName}
                            </span>
                            <span
                              className={`listsearch_ul_stockbadge ${
                                stock.type === "mf"
                                  ? "badge-info"
                                  : "badge-success"
                              }`}
                            >
                              {stock?.type}
                            </span>
                          </button>
                        ))}
                      </>
                    ) : (
                      <p>No suggestions found</p>
                    )}

                    {loading && <p>Loading...</p>}
                    {!hasMore && <p>No more suggestions</p>}
                  </div>
                )}
                {watchlist.map((stock, index) => (
                  <button
                    key={index}
                    className="listsearch_ul my-2 btn"
                    onClick={() => handleClickStock({ ...stock }, "list")}
                  >
                    <span className="listsearch_ul_stockname">
                      {stock?.shortSchemeDescrip || stock?.stockName}
                    </span>
                    <div className="d-flex">
                      <span
                        className={`listsearch_ul_stockbadge ${
                          stock.type === "mf" ? "badge-info" : "badge-success"
                        }`}
                      >
                        {stock?.type}
                      </span>
                      {/* <button className="btn round_btn">X</button> */}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default WatchListPage;
