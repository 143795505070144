import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { removeAccess, removeToken, setLocalStorage } from ".";
import { encrypt, encryptData } from "./CryptoJS";

const LogoutButton = ({ logoutType, logoutAction }) => {
  const navigate = useNavigate();
  // Define the logout function
  const logoutfunction = useCallback(
    (logoutType, logoutAction) => {
      navigate(
        "/" +
          encrypt("Redirectslogout") +
          `/${logoutType}` +
          `/${encryptData(logoutAction)}`
      );
      removeToken();
      removeAccess();
      
      window.location.reload(true);
    },
    [navigate]
  );

  useEffect(() => {
    if (logoutAction == "system" || logoutAction == "Bad_credentials") {
      logoutfunction(logoutType, logoutAction);
    }
    return () => {
      if (logoutAction == "user") {
      }
    };
  }, [logoutAction]);

  return (
    <button
      className="btn"
      onClick={() => logoutfunction(logoutType, logoutAction)} // Call the function when button clicked
    >
      <FontAwesomeIcon icon={faRightFromBracket} /> Logout
    </button>
  );
};

export default LogoutButton;
