import { PropTypes } from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Apiurl } from "../../util/apiurl";
import { decryptData } from "../../util/Authenticate/CryptoJS";
import axiosInstance from "../../util/axiosInstance";
import Loader from "../../util/Loader";
import LoadFromBase64pdf from "../../util/LoadFromBase64Example";
import AppToaster from "../../util/Toaster/AppToaster";
import {
  clientData,
  reportFormatData,
} from "../../ValidationPattern/Messaging";
import { DownloadReportPDFFunction } from "../Uploads/UploasAction";
import ButtonComp from "./../../Component/ButtonComp/ButtonComp";
import InputSelect from "./../../Component/ComponentsInput/InputSelect";

const ClientReportsFormComponent = ({
  initialData,
  onSubmit,
  onFileChangeNew,
  formData,
  pageName,
}) => {
  const mode = decryptData(useParams().mode);
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const [reportType, setReportType] = useState("Held");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [handleAction, setHandleAction] = useState(null);
  const [viewReportPDF, setViewReportPDF] = useState(null);
  const [account, setAccount] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      ...initialData,
      reportType: "Held", // Default value for the radio button
    },
  });
  const useFromProps = {
    register,
    errors,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
    getUserDetails();
  }, [initialData, reset]);

  const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.userDetails);
      const userData = response.data;
      fetchAllAccounByClient(userData.clientId);
    } catch (error) {
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      setLoading(false);
    }
  };

  const fetchAllAccounByClient = async (id) => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(
        `${Apiurl.allAccountByClientReport}${id}`
      );

      setLoading(false);
      let result = response.data;
      setAccount([]);
      Object.values(result).map((item) => {
        let SingleData = {
          label: item.name,
          value: item.id,
        };
        setAccount((prevData) => [...prevData, SingleData]);
      });
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const handleExport = useCallback((userAction) => {
    setHandleAction(null);
    setViewReportPDF(null);
    setHandleAction(userAction);
    handleReportsExport();
  }, []);
  const handleReportsExport = async () => {
    setData(null);
    let url;
    if (pageName == "Holding") {
      url = Apiurl.generateReportHolding;
    } else if (pageName == "Capital") {
      url = Apiurl.generateReportCapitalGain;
    }
    setLoading(true);
    let payload = {
      fileName: "",
      message: "",
    };
    try {
      const response = await axiosInstance.post(url, payload);
      if (response) {
        // setLoading(false);
        toast.success(response.data.message);
        setData(response.data.reportFileId);
        fetchData();
        // DownloadReportPDFFunction("HOLDING_REPORT", "28b50983-e111-44a0-907a-dc6204cfe4f3");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      // setLoading(false);
    }
  };

  // Callback function for polling
  const fetchData = useCallback(() => {
    let tempageName =
      pageName == "Holding" ? "HOLDING_REPORT" : "CAPITAL_GAIN_REPORT";
    // Simulate the API call by using a function like GeneratedReportPDFFunction
    GeneratedReportPDFFunction(tempageName, data);

    // Check if the response is true
    if (isSuccess === true) {
      if (handleAction === "export") {
        setHandleAction(null);
        setViewReportPDF(null);
        DownloadReportPDFFunction(tempageName, data);
      }
      if (handleAction === "view") {
        setHandleAction(null);
        setViewReportPDF(null);
        ViewReportPDFFunction(tempageName, data);
      }
      setLoading(false);
    } else {
      setIsSuccess(false); // Continue polling if response is not true
      setLoading(true);
    }
  }, [data]); // Include `data` as a dependency if it changes

  useEffect(() => {
    // Start polling with setInterval (runs every 3 seconds)
    const intervalId = setInterval(fetchData, 3000);

    // Clear the interval if polling is successful
    if (isSuccess) {
      clearInterval(intervalId);
    } else {
      if (data == null) {
        clearInterval(intervalId);
      }
    }

    // Cleanup function to clear interval on component unmount or when polling stops
    return () => clearInterval(intervalId);
  }, [fetchData]); // Depend on `fetchData` and `isSuccess`

  const GeneratedReportPDFFunction = async (reportType, fileId) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.checkIfReportIsGenerated}reportType=${reportType}&fileId=${fileId}`
      );
      // console.log("response.data", response.data);

      if (response.data === true) {
        // Continue polling if response is not true
        if (handleAction === "export") {
          setHandleAction(null);
          setViewReportPDF(null);
          DownloadReportPDFFunction(reportType, fileId);
        }
        if (handleAction === "view") {
          setHandleAction(null);
          setViewReportPDF(null);
          ViewReportPDFFunction(reportType, fileId);
        }

        setLoading(false);
        setData(null);
        setIsSuccess(false);
      }
      setIsSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const ViewReportPDFFunction = async (reportType, fileId) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.generateviewReport}reportType=${reportType}&fileId=${fileId}`
      );
      let result = response.data;
      // console.log("response.data", result);
      toast.success("");
      <AppToaster duration={50000} Toastericon={"👏"} />;
      setLoading(false);
      setViewReportPDF(result);
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const handleReportTypeChange = (value) => {
    setReportType(value);
    alert("Report type changed");
  };

  return (
    <>
      {loading ? (
        <Loader pagename="Updating ..." />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-3">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="filter-section position-relative">
                  <label className="mb-1">Select Date</label>
                  <input
                    type="date"
                    className="filter-input form-control"
                    {...register("selectDate")}
                    placeholder="Select Date"
                    style={{
                      border: "1px solid #DEE2E6",
                      borderRadius: "8px",
                      padding: "10px 12px",
                      fontSize: "14px",
                      color: "#495057",
                      outline: "none",
                    }}
                  />
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2">
                <InputSelect
                  {...{
                    register,
                    errors,
                    setValue,
                    trigger,
                    control,
                    watch,
                    getValues,
                  }}
                  divClassName={"form-floating-label"}
                  registerName={"client"}
                  // mandatory={true}
                  labelName={t("Form:App_lms_Form_00086")}
                  options={clientData}
                  value={""} // Adjust this based on the mode or input value
                />
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <InputSelect
                  {...{
                    register,
                    errors,
                    setValue,
                    trigger,
                    control,
                    watch,
                    getValues,
                  }}
                  registerName={"account"}
                  // mandatory={true}
                  labelName={t("Form:App_lms_Form_00087")}
                  options={account}
                  value={""} // Adjust this based on the mode or input value
                />
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 radioGroup">
                {["Held", "Held Away", "Both"].map((type) => (
                  <label key={type} className="radioLabel">
                    <input
                      className="me-1"
                      type="radio"
                      value={type}
                      {...register("reportType")}
                    />
                    {type}
                  </label>
                ))}
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <InputSelect
                  {...{
                    register,
                    errors,
                    setValue,
                    trigger,
                    control,
                    watch,
                    getValues,
                  }}
                  registerName={"Select Format: PDF, Excel, JPG"}
                  // mandatory={true}
                  labelName={t("Form:App_lms_Form_00088")}
                  options={reportFormatData}
                  value={""} // Adjust this based on the mode or input value
                />
              </div>
            </div>

            <div className="d-flex">
              <div className="me-1">
                {" "}
                <ButtonComp
                  wrapperName="btn_wrapper"
                  type="button"
                  btnStyle="box"
                  btnText={"Export"}
                  onClick={() => handleExport("export")}
                />
              </div>
              <div className="ms-1">
                <ButtonComp
                  wrapperName="btn_wrapper"
                  type="button"
                  btnStyle="box"
                  btnText={"View"}
                  onClick={() => handleExport("view")}
                />
              </div>
            </div>
          </form>
          <div div className="mt-5">
            {viewReportPDF !== null && (
              <LoadFromBase64pdf pdfdata={viewReportPDF} />
            )}
          </div>
        </>
      )}
    </>
  );
};

ClientReportsFormComponent.propTypes = {
  initialData: PropTypes.any,
  onSubmit: PropTypes.func,
};
export default ClientReportsFormComponent;
