// Sidebar.js
import {
  faBuilding,
  faFileAlt,
  faFileLines,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightArrowLeft,
  faArrowUpRightDots,
  faBars,
  faBookBookmark,
  faChartPie,
  faFileArrowUp,
  faHouseChimney,
  faTimes,
  faUserGear,
  faUsersGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import {
  decryptData,
  encrypt,
  encryptData,
} from "../util/Authenticate/CryptoJS";
import AuthorizedFunction from "../util/Authenticate/currentRealmRole";
import "./sidebar.scss";

const SidebarComponent = () => {
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [suburrentUrl, setSubCurrentUrl] = useState("");
  const [urlDomain, setUrlDomain] = useState("");

  useEffect(() => {
    fetchUrl();
  }, []);

  const [collapsedvalue, setCollapsedvalue] = useState(true);

  const handleToggle = () => {
    setCollapsedvalue((prevValue) => !prevValue);
    if (collapsedvalue) {
      document.querySelector(".contentoverlay").classList.add("blur_body_bg");
      document.querySelector(".contentpagediv").classList.add("blur_body");
      document
        .querySelector(".contentoverlay")
        .classList.remove("noblur_body_bg");
    } else {
      document
        .querySelector(".contentoverlay")
        .classList.remove("blur_body_bg");
      document.querySelector(".contentpagediv").classList.remove("blur_body");

      document.querySelector(".contentoverlay").classList.add("noblur_body_bg");
    }
  };

  const [activeItemId, setActiveItemId] = useState(null); // Default active item ID
  const isExpanded = true;

  const handleMenuItemClick = (id) => {
    setActiveItemId();
    setCurrentUrl();
    setActiveItemId(id);

    setTimeout(() => {
      fetchUrl();
    }, 500);
  };

  //Fetch url
  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    let urlElelement2 = urlElements[4];
    let urlElelement1 = urlElements[2];
    setUrlDomain(`${urlElelement1}`);
    setCurrentUrl(`${urlElelement}`);
    setSubCurrentUrl(decryptData(urlElelement2));
  };

  return (
    <div>
      <Sidebar
        className={collapsedvalue ? "no_blur" : "blur_bg"}
        height="100%"
        min-height="30rem"
        width="200px"
        collapsedWidth="4rem"
        color="$app_w_color"
        style={{
          height: "100%",
          top: "auto",
          border: 0,
          position: "sticky",
          padding: "0rem",
          margin: "0rem",
          zIndex: 9,
          minHeight: "30rem",
        }}
        collapsed={collapsedvalue}
        transitionDuration={800}
      >
        <div
          className={
            collapsedvalue ? "navbtn collapsedbvbar" : "navbtn collapsedbnotbar"
          }
        >
          <button type="button" onClick={handleToggle} className={"btn"}>
            <FontAwesomeIcon icon={collapsedvalue ? faBars : faTimes} />
          </button>
        </div>

        <Menu
          menuItemStyles={{
            button: ({ level, active }) => {
              if (level === 0 || level === 1) {
                return {
                  backgroundColor: active ? "#889BFF" : "",
                  color: "white",
                  borderRadius: active ? "10px" : null,

                  "&:hover": {
                    backgroundColor: "#889BFF",
                    color: "white",
                  },
                };
              }
            },
          }}
          collapsed={collapsedvalue}
          transitionDuration={800}
        >
          {/********************** Menu Starts for client ***********************/}
          {AuthorizedFunction(["client"]) && (
            <>
              {" "}
              <MenuItem
                active={
                  activeItemId === "ClientDashboard" ||
                  currentUrl === encrypt("ClientDashboard")
                }
                icon={<FontAwesomeIcon icon={faHouseChimney} />}
                onClick={() => handleMenuItemClick("ClientDashboard")}
                component={<Link to={"/" + encrypt("ClientDashboard")} />}
                className="dflexMenu"
                title="Dashboard"
              >
                Home
              </MenuItem>
              <SubMenu
                className="MenuItem dSubflexMenu"
                icon={<FontAwesomeIcon icon={faFileLines} />}
                title="Client Reports"
                label="Client Reports"
              >
                <MenuItem
                  active={activeItemId === "ClientHoldingReport"}
                  icon={
                    <FontAwesomeIcon icon={faFileLines} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("ClientHoldingReport")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("ClientReportsComponent") +
                        `/${encryptData("ClientHoldingReport")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Holding Report"
                >
                  Holding Report
                </MenuItem>
                <MenuItem
                  active={activeItemId === "CapitalGainReport"}
                  icon={
                    <FontAwesomeIcon icon={faFileLines} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("CapitalGainReport")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("ClientReportsComponent") +
                        `/${encryptData("CapitalGainReport")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Capital Gain"
                >
                  Capital Gain
                </MenuItem>
                <MenuItem
                  active={activeItemId === "ClientTransactionReport"}
                  icon={
                    <FontAwesomeIcon icon={faFileLines} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("ClientTransactionReport")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("ClientReportsComponent") +
                        `/${encryptData("ClientTransactionReport")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Transaction Report"
                >
                  Transaction Report
                </MenuItem>
              </SubMenu>
              <SubMenu
                className="MenuItem dSubflexMenu"
                icon={<FontAwesomeIcon icon={faChartPie} />}
                title="Fund Analytics"
                label="Fund Analytics"
              >
                <MenuItem
                  active={activeItemId === "FundCompare"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("FundCompare")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("FundAnalyticsComponent") +
                        `/${encryptData("FundCompare")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Fund Compare"
                >
                  Fund Compare
                </MenuItem>
                <MenuItem
                  active={activeItemId === "FundOverlap"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("FundOverlap")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("FundAnalyticsComponent") +
                        `/${encryptData("FundOverlap")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Fund Overlap"
                >
                  Fund Overlap
                </MenuItem>
                <MenuItem
                  active={activeItemId === "StocksOverlapping"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("StocksOverlapping")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("FundAnalyticsComponent") +
                        `/${encryptData("StocksOverlapping")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Stocks Overlapping"
                >
                  Stocks Overlapping
                </MenuItem>
                <MenuItem
                  active={activeItemId === "SecurityHolding"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("SecurityHolding")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("FundAnalyticsComponent") +
                        `/${encryptData("SecurityHolding")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Security Holding"
                >
                  Security Holding
                </MenuItem>
                <MenuItem
                  active={activeItemId === "SectorHolding"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("SectorHolding")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("FundAnalyticsComponent") +
                        `/${encryptData("SectorHolding")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Sector Holding"
                >
                  Sector Holding
                </MenuItem>
              </SubMenu>
              <MenuItem
                active={
                  activeItemId === "RiskProfiling" ||
                  currentUrl === encrypt("RiskProfiling")
                }
                icon={<FontAwesomeIcon icon={faUserGear} />}
                onClick={() => handleMenuItemClick("RiskProfiling")}
                component={<Link to={"/" + encrypt("RiskProfiling")} />}
                className="dflexMenu"
                title="Risk Profiling"
              >
                Risk Profiling
              </MenuItem>
            </>
          )}
          {/********************** Menu Starts for RM dashboard *******************/}
          {AuthorizedFunction(["relationship_manager"]) && (
            <>
              {" "}
              <MenuItem
                active={
                  activeItemId === "Rmdashboard" ||
                  currentUrl === encrypt("Rmdashboard")
                }
                icon={<FontAwesomeIcon icon={faHouseChimney} />}
                onClick={() => handleMenuItemClick("Rmdashboard")}
                component={<Link to={"/" + encrypt("Rmdashboard")} />}
                className="dflexMenu"
                title="RM Dashboard"
              >
                Home
              </MenuItem>
              <SubMenu
                icon={
                  <FontAwesomeIcon
                    icon={faFileLines}
                    defaultCollapsed={true}
                    collapsed={!isExpanded}
                  />
                }
                title="Reports"
                label="Reports"
                className="dSubflexMenu"
                active={
                  activeItemId === "CorporateReports" ||
                  activeItemId === "EnitityReport" ||
                  currentUrl === encrypt("CorporateReports") ||
                  currentUrl === encrypt("EnitityReport")
                }
              >
                <MenuItem
                  active={
                    activeItemId === "CorporateReports" ||
                    currentUrl === encrypt("CorporateReports")
                  }
                  icon={<FontAwesomeIcon icon={faUsersGear} />}
                  onClick={() => handleMenuItemClick("CorporateReports")}
                  component={<Link to={"/" + encrypt("CorporateReports")} />}
                  className="SubMenudflexMenu"
                >
                  {t("Common:App_lms_Common_00247")}
                </MenuItem>

                <MenuItem
                  active={
                    activeItemId === "EnitityReport" ||
                    currentUrl === encrypt("EnitityReport")
                  }
                  icon={<FontAwesomeIcon icon={faBuilding} />}
                  onClick={() => handleMenuItemClick("EnitityReport")}
                  component={<Link to={"/" + encrypt("EnitityReport")} />}
                  className="SubMenudflexMenu"
                >
                  {t("Common:App_lms_Common_00248")}
                </MenuItem>
              </SubMenu>
              <SubMenu
                className="MenuItem dSubflexMenu"
                icon={
                  <FontAwesomeIcon
                    icon={faFileArrowUp}
                    defaultCollapsed={true}
                    collapsed={!isExpanded}
                  />
                }
                active={
                  activeItemId === "UploadsComponent" ||
                  currentUrl === encrypt("UploadsComponent")
                }
                title="Uploads"
                label="Uploads"
              >
                <MenuItem
                  active={
                    activeItemId === "UploadsComponentHolding" ||
                    suburrentUrl === "Holding"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("UploadsComponentHolding")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("Holding")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Holding"
                >
                  Holding
                </MenuItem>
                <MenuItem
                  active={
                    activeItemId ===
                      "UploadsComponentHoldingHoldingUnderlying" ||
                    suburrentUrl === "HoldingUnderlying"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() =>
                    handleMenuItemClick("UploadsComponentHoldingUnderlying")
                  }
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("HoldingUnderlying")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Holding Underlying"
                >
                  Holding Underlying
                </MenuItem>
                <MenuItem
                  active={
                    activeItemId === "UploadsComponentSectorClassification" ||
                    suburrentUrl === "SectorClassification"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() =>
                    handleMenuItemClick("UploadsComponentSectorClassification")
                  }
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("SectorClassification")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Sector Classification"
                >
                  Sector Classification
                </MenuItem>
                <MenuItem
                  active={
                    activeItemId === "UploadsComponentTransaction" ||
                    suburrentUrl === "Transaction"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() =>
                    handleMenuItemClick("UploadsComponentTransaction")
                  }
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("Transaction")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Transaction"
                >
                  Transaction
                </MenuItem>
                <MenuItem
                  active={
                    activeItemId ===
                      "UploadsComponentInstrumentSectorMapping" ||
                    suburrentUrl === "InstrumentSectorMapping"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() =>
                    handleMenuItemClick(
                      "UploadsComponentInstrumentSectorMapping"
                    )
                  }
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("InstrumentSectorMapping")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Instrument Sector Mapping"
                >
                  Instrument Sector Mapping
                </MenuItem>
                <MenuItem
                  active={
                    activeItemId === "UploadsComponentIssuers" ||
                    suburrentUrl === "Issuers"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("UploadsComponentIssuers")}
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("Issuers")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Issuers"
                >
                  Issuers
                </MenuItem>
                <MenuItem
                  active={
                    activeItemId === "UploadsCLIENT_MASTER_BULK_UPLOAD" ||
                    suburrentUrl === "CLIENT_MASTER_BULK_UPLOAD"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() =>
                    handleMenuItemClick("UploadsCLIENT_MASTER_BULK_UPLOAD")
                  }
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("CLIENT_MASTER_BULK_UPLOAD")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Issuers"
                >
                  Client Onboarding
                </MenuItem>
                {/* CLIENT_MASTER_BULK_UPLOAD */}
                <MenuItem
                  active={
                    activeItemId === "UploadsComponentInstrument" ||
                    suburrentUrl === "Instrument"
                  }
                  icon={
                    <FontAwesomeIcon icon={faFileArrowUp} className="d-none" />
                  }
                  onClick={() =>
                    handleMenuItemClick("UploadsComponentInstrument")
                  }
                  component={
                    <Link
                      to={
                        "/" +
                        encrypt("UploadsComponent") +
                        `/${encryptData("Instrument")}`
                      }
                    />
                  }
                  className="SubMenudflexMenu"
                  title="Instrument"
                >
                  Instrument
                </MenuItem>
              </SubMenu>
            </>
          )}
          {AuthorizedFunction(["super_admin"]) && (
            <>
              {" "}
              <MenuItem
                active={
                  activeItemId === "Defaultdashboard" ||
                  currentUrl === encrypt("Defaultdashboard")
                }
                icon={<FontAwesomeIcon icon={faHouseChimney} />}
                onClick={() => handleMenuItemClick("Defaultdashboard")}
                component={<Link to={"/" + encrypt("Defaultdashboard")} />}
                className="dflexMenu"
                title="Dashboard"
              >
                Home
              </MenuItem>
            </>
          )}

          {AuthorizedFunction(["operation_manager"]) && (
            <>
              <MenuItem
                active={
                  activeItemId === "Operationdashboard" ||
                  currentUrl === encrypt("Operationdashboard")
                }
                icon={<FontAwesomeIcon icon={faHouseChimney} />}
                onClick={() => handleMenuItemClick("Operationdashboard")}
                component={<Link to={"/" + encrypt("Operationdashboard")} />}
                className="dflexMenu"
                title="Dashboard"
              >
                Home
              </MenuItem>

              <MenuItem
                active={
                  activeItemId === "SIPExposure" ||
                  currentUrl === encrypt("SIPExposure")
                }
                icon={<FontAwesomeIcon icon={faChartPie} />}
                onClick={() => handleMenuItemClick("SIPExposure")}
                component={<Link to={"/" + encrypt("RiskProfiling")} />}
                className="dflexMenu"
                title="SIP Exposure"
              >
                SIP Exposure
              </MenuItem>
              <MenuItem
                active={
                  activeItemId === "AUMReport" ||
                  currentUrl === encrypt("AUMReport")
                }
                icon={<FontAwesomeIcon icon={faFileAlt} />}
                onClick={() => handleMenuItemClick("AUMReport")}
                component={<Link to={"/" + encrypt("RiskProfiling")} />}
                className="dflexMenu"
                title="AUM Report"
              >
                AUM Report
              </MenuItem>
              <MenuItem
                active={
                  activeItemId === "Transaction" ||
                  currentUrl === encrypt("Transaction")
                }
                icon={
                  <FontAwesomeIcon
                    icon={faArrowRightArrowLeft}
                    style={{ transform: "rotate(90deg)" }}
                  />
                }
                onClick={() => handleMenuItemClick("Transaction")}
                component={<Link to={"/" + encrypt("RiskProfiling")} />}
                className="dflexMenu"
                title="Transaction"
              >
                Transaction
              </MenuItem>
              <MenuItem
                active={
                  activeItemId === "AUMGrowth" ||
                  currentUrl === encrypt("AUMGrowth")
                }
                icon={<FontAwesomeIcon icon={faArrowUpRightDots} />}
                onClick={() => handleMenuItemClick("AUMGrowth")}
                component={<Link to={"/" + encrypt("RiskProfiling")} />}
                className="dflexMenu"
                title="AUM Growth"
              >
                AUM Growth
              </MenuItem>
              <SubMenu
                className="MenuItem dSubflexMenu"
                icon={<FontAwesomeIcon icon={faBookBookmark} />}
                title="Instrument Master"
                label="Instrument Master"
              >
                <MenuItem
                  active={activeItemId === "AssetClass"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("AssetClass")}
                  component={<Link to={"/" + encrypt("AssetClassList")} />}
                  className="SubMenudflexMenu"
                  title="Asset Class"
                >
                  Asset Class
                </MenuItem>
                <MenuItem
                  active={activeItemId === "CreateInstrument"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("CreateInstrument")}
                  component={<Link to={"/" + encrypt("CreateInstrumentLanding")+
                    `/${encryptData("List")}`} />}
                  className="SubMenudflexMenu"
                  title="Create Instrument"
                >
                  Create Instrument
                </MenuItem>
                <MenuItem
                  active={activeItemId === "InstrumentCategory"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("InstrumentCategory")}
                  component={<Link to={"/" + encrypt("InstrumentCategoryList")} />}
                  className="SubMenudflexMenu"
                  title="Instrument Category"
                >
                  Instrument Category
                </MenuItem>
                <MenuItem
                  active={activeItemId === "InstrumentType"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("InstrumentType")}
                  component={<Link to={"/" + encrypt("InstrumentTypeList")} />}
                  className="SubMenudflexMenu"
                  title="Instrument Type"
                >
                  Instrument Type
                </MenuItem>
                <MenuItem
                  active={activeItemId === "Sector"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("Sector")}
                  component={<Link to={"/" + encrypt("SectorList")} />}
                  className="SubMenudflexMenu"
                  title="Sector"
                >
                  Sector
                </MenuItem>
                <MenuItem
                  active={activeItemId === "CreditRating"}
                  icon={
                    <FontAwesomeIcon icon={faChartPie} className="d-none" />
                  }
                  onClick={() => handleMenuItemClick("CreditRating")}
                  component={<Link to={"/" + encrypt("CreditRatingList")} />}
                  className="SubMenudflexMenu"
                  title="Credit Rating"
                >
                  Credit Rating
                </MenuItem>
              </SubMenu>
            </>
          )}
          {/* Operation User */}
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SidebarComponent;

SidebarComponent.propTypes = {
  collapsedvalue: PropTypes.any,
};
