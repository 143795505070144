import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { encrypt } from "../../util/Authenticate/CryptoJS";
import { login, setLocalStorage } from "../../util/Authenticate/index";
import AppToaster from "../../util/Toaster/AppToaster";
import { Apiurl } from "../../util/apiurl";
import axiosInstance from "../../util/axiosInstance";
import FormComponent from "./FormComponent";
const generateRandomString = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";
  for (let i = 0; i < 6; i++) {
    captcha += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return captcha;
};
const LoginFormComponents = () => {
  const [captcha, setCaptcha] = useState(generateRandomString());
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["Common", "Messages", "Form"]);

  const [data, setData] = useState(null);
  // Simulate loading state (remove this if loading is based on an actual request)

  const handlecaptcha = () => {
    setCaptcha(generateRandomString());
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setData({
      inputCaptcha: captcha,
    });
  }, [captcha]);

  // Handle form submission
  const handleAddSubmit = (data) => {
    const payload = {
      username: data.username,
      password: data.password,
    };

    const logtype = data.LoginAs === undefined ? "client" : data.LoginAs.value;
    let appHostname = window.location.hostname;
    if (appHostname === "localhost") {
      submitData(payload, logtype);
    } else {
      if (data.inputCaptcha === data.Captcha) {
        submitData(payload, logtype); 
      } else {
        setCaptcha(generateRandomString());
        toast.error("Invalid Captcha");
        <AppToaster duration={50000} icon={"dd"} />;
      }
    }

    // submitData(payload, logtype);
  };

  // Submit login data to the API
  const submitData = async (payload, logtype) => {

    try {
      const response = await axiosInstance.post(Apiurl.api_login, payload);
      const tokenData = response.data;

      if (tokenData) {
        // Perform login with the received token
        login(tokenData, logtype);

        // Navigate to the dashboard page
        // navigate(`/${encrypt("ClientDashboard")}`);
        switch (logtype) {
          case "client":
            navigate(`/${encrypt("ClientDashboard")}`);
            break;
          case "relationship_manager":
            navigate(`/${encrypt("Rmdashboard")}`);
            break;
          case "operation_manager":
            navigate(`/${encrypt("Operationdashboard")}`);
            break;
          case "super_admin":
            navigate(`/${encrypt("Defaultdashboard")}`);
            break;
          default:
            navigate(`/${encrypt("Defaultdashboard")}`);
            break;
        }
        setLocalStorage(encrypt("counter"), "3");
        toast.success(t("loginSuccess"));
        window.location.reload(true);
      } else {
        toast.error(t("login Failed 1"));
      }
    } catch (error) {
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="apph100 d-flex align-content-center container-fluid ">
        <FormComponent
          onSubmit={handleAddSubmit}
          initialData={data}
          Showrecaptchabtn={handlecaptcha}
        />
      </div>
    </>
  );
};

export default LoginFormComponents;
