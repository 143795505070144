import React, { useState } from "react";
import "./CustomCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronDown,
  faSquareCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import AppModal from "../Modal/AppModal";
import SelectElement from "../ComponentsInput/InputSelect";
import { useForm } from "react-hook-form";
import AgTable from "./AgTable";

const CustomCard = ({
  title,
  data,
  weight,
  mutualFundOptions,
  onSelectFund,
  dropdownOptions,
  
}) => {

    const {
      register,
      handleSubmit,
  
      formState: { errors },
      reset,
      setValue,
      trigger,
      control,
      watch,
      getValues,
    } = useForm({ defaultValues:"" });
    const useFromProps = {
      register,
      errors,
      setValue,
      trigger,
      control,
      watch,
      getValues,
    };

    const getRowStyle = (params) => {
      return {
        backgroundColor: params.node.rowIndex % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternating colors
      };
    };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rowData2, setRowData2] = useState([]);
  

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev); // Toggle dropdown visibility
    // console.log("Clicked", dropdownOpen, mutualFundOptions);//
  };

  const handleOptionSelect = (option) => {
    onSelectFund(option); // Handle selection logic
    setDropdownOpen(false); // Close dropdown after selecting
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleAction = () => {};

  const columnDefs = [
    {
      headerName: "Mutual Fund Name",
      field: "firstName",
      sortable: true,
      filter: true,
      minWidth: 180,
    },
    {
      headerName: "Current Value",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),

      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Invested Value",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "IRR",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Gain/Loss",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "STCG",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "LTCG",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Weight(%)",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
  ];

  return (
    <div className="custom-wrapper">
      <div className="custom-header"></div>
      <div className="custom-body">
        <h2 className="custom-title">{title}</h2>
        {data.map((item, index) => (
          <div key={index} className="custom-item">
            <h3 className="custom-item-label">{item.label}</h3>
            <p className="custom-item-value">{item.value}</p>
          </div>
        ))}
        <div className="custom-weight">
          <h3 className="custom-item-label">Weight (%)</h3>
          <p className="custom-weight-value">{weight}%</p>
        </div>
        <div className="custom-weight-bar-container">
          <div className="custom-weight-bar-bg">
            <div
              className="custom-weight-bar-fill"
              style={{ width: `${weight}%` }}
            ></div>
          </div>
        </div>
        {/* Dropdown */}
        <div className="custom-dropdown">
          {/* <SelectElement
            {...useFromProps}
            useForm={useForm}
            register={() => {}}
            // setValue={()=> {}}
            // errors={{}}
            // divClassName={{}}
            // control={{}}
            // isMulti = {false}
            // readOnly = {false}
            previewFlag={""}
            onSelect={() => {}}
            handleInputChange={() => {}}
            registerName={"holsdingsDropdown"}
            mandatory={true}
            labelName=""
            options={dropdownOptions}
          /> */}
          <FontAwesomeIcon
            icon={faSquareCaretDown}
            className="dropdown-icon"
            onClick={handleOpenModal}
          />
        </div>
      </div>
      <AppModal
        isOpen={showModal}
        onClose={handleCloseModal}
        handleActon={handleAction} // Ensure this is defined and passed correctly
        ModalTitle="Product List"
        Modalsize="lg"
        buttonConfigs={[{ text: "Continue", icon: null, action: "continue" }]}
        ModalBody={
           <div className="dashbordchart mixbar">
                                <AgTable
                                  columnDefs={columnDefs}
                                  rowData={rowData2}
                                  filenames="TopHoldings"
                                  StyleClass="btn btn-primary"
                                  type="table"
                                  gridOptions={{
                                    getRowStyle,
                                  }}
                                />
                              </div>
        }
        ModalType="Watchlist"
        ModalScrollable={true}
        // ReactOdometervalue={seconds}
      />
    </div>
  );
};

export default CustomCard;
