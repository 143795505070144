import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ApppieChart from "../../Component/ComponentsChart/ApppieChart";
import Pagehader from "../../Layout/Pagehader";
import { decryptData } from "../../util/Authenticate/CryptoJS";
import "../RiskProfiling/RiskProfiling.scss";
import RiskGauge from "./RiskGuage";

export default function RiskProfiling() {
  const Marketdata = [
    {
      label: "AUM",
      data: [50, 50],
      backgroundColor: ["#4A75CB", "#889BFF"],
      borderColor: ["#4A75CB", "#889BFF"],
      borderWidth: 1,
    },
  ];
  const MarketDatalabels = ["Equity", "Debt"];

  const modes = decryptData(useParams().mode);
  console.log("modes", modes);
  const { t } = useTranslation(["Common", "Messages", "Form"]);

  const breadcrumbItems = [
    {
      label: t("Common:App_lms_Common_00259"),
    },
  ];

  return (
    <>
      <Pagehader
        Breadcrumbshow={true}
        breadcrumbItems={breadcrumbItems}
      ></Pagehader>
      <div className="pagebody">
        <div className="row chart-container">
          {/* Risk Gauge Column */}
          <div className="col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 riskdiv p-5">
            <RiskGauge />
          </div>

          {/* Separator Column */}
          <div className="col-1 separatorMainDiv">
            <div className="separator"></div>
          </div>

          {/* Pie Chart Column */}
          <div className="col-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 pieChartMainDiv p-5">
            <h3 className="dashbordchartname">
              <strong>Recommended Asset Allocation</strong>
            </h3>
            <div className="pieChartDiv">
              <ApppieChart
                type={"DoughnutChart"}
                legendposition={"bottom"}
                legenddisplay={true}
                Chartdata={Marketdata}
                Chartlabels={MarketDatalabels}
                cutout={"70"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
