import React from "react";
import ReactApexChart from "react-apexcharts";

const TreemapChart = ({ data, label, colors = [] }) => {
  const options = {
    legend: {
      show: true,
    },
    chart: {
      height: 350,
      type: "treemap",
    },
    title: {
      text: label || "",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontWeight: "bold",
        colors: ["#000000"]
      },
      formatter: function (text, op) {
        const maxLength = 15; // Maximum length for labels
        const trimmedText = text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
        return `${trimmedText}(${op.value}%)`; // Show trimmedText label with value
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val}%`; // Tooltip shows the value as percentage
        },
      },
      // x: {
      //   show: true, // Show full label in the tooltip
      // },
    },
    colors:
      colors?.length > 0
        ? colors
        : ["#285a66", "#307585", "#4e89a0", "#609cb7", "#7eb3d4", "#99cfe4"],
    plotOptions: {
      treemap: {
        enableShades: false, // Disable shading
        distributed: true, // Apply colors to individual blocks
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={data}
        type="treemap"
        height={300}
      />
    </div>
  );
};

export default TreemapChart;
