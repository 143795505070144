import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import WatchListModal from "../Component/Modal/WatchListModel";
import ClientReportsComponent from "../page/ClientReportsComponent/ClientReportsComponent";
import ClientDashboard from "../page/Dashboard/ClientDashboard/ClientDashboard";
import DashboardLandingPage from "../page/Dashboard/DashboardLandingPage";
import OperationDashboard from "../page/Dashboard/OperationDashboard/OperationDashboard";
import Rmdashboard from "../page/Dashboard/RmDashboard/Rmdashboard";
import FundAnalyticsComponent from "../page/FundAnalytics/FundAnalyticsComponent";
import CreditRatingFormComponent from "../page/InstrumentMaster/CreditRating/CreditRatingFormComponent";
import CreditRatingList from "../page/InstrumentMaster/CreditRating/CreditRatingList";
import SectorList from '../page/InstrumentMaster/CreditRating/Sector/SectorList';
import RiskProfiling from "../page/RiskProfiling/RiskProfiling";
import UploadsComponent from "../page/Uploads/UploadsComponent";
import { encrypt } from "../util/Authenticate/CryptoJS";
import AuthorizedFunction from "../util/Authenticate/currentRealmRole";
import CorporateReports from "./../page/CorporateReports/CorporateReports";
import Defaultdashboard from "./../page/Dashboard/Defaultdashboard/Defaultdashboard";
import EnitityReport from "./../page/EnitityReport/EnitityReport";
import Loader from "./../util/Loader";
import SectorListFormComponent from "../page/InstrumentMaster/CreditRating/Sector/SectorListFormComponet";
import InstrumentTypeList from "../page/InstrumentMaster/InstrumentType/InstrumentTypeList";
import InstrumentTypeFormComponent from "../page/InstrumentMaster/InstrumentType/InstrumentTypeFormComponent";
import InstrumentCategoryList from '../page/InstrumentMaster/InstrumentCategory/InstrumentCategoryList';
import InstrumentCategoryFormComponent from "../page/InstrumentMaster/InstrumentCategory/InstrumentCategoryFormComponent";
import AssetClassList from "../page/InstrumentMaster/AssetClass/AssetClassList";
import AssetClassFormComponent from "../page/InstrumentMaster/AssetClass/AssetClassFormComponent";
import CreateInstrumentList from "../page/InstrumentMaster/CreateInstrument/CreateInstrumentList";
import CreateInstrumentFormComponent from "../page/InstrumentMaster/CreateInstrument/CreateInstrumentFormCompoent";
import CreateInstrumentLanding from "../page/InstrumentMaster/CreateInstrument/CreateInstrumentLanding";


const Dashboard = React.lazy(() =>
  import("../page/Dashboard/ClientDashboard/ClientDashboard")
);

// Import the NotFound component
const NotFound = React.lazy(() => import("../page/PageNotFound"));

export default function AppRoutes() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Simulate loading for 1-2 seconds after login
    const timer = setTimeout(() => {
      setLoading(false);
      // You can redirect after login here if needed
    }, 1500);

    return () => clearTimeout(timer);
  }, [location]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    ); // Or show a spinner
  }
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<DashboardLandingPage />} />

        {/********************** Menu Starts for client ***********************/}
        {AuthorizedFunction(["client"]) && (
          <>
            <Route
              path={"/" + encrypt("ClientDashboard")}
              element={<ClientDashboard />}
            />
            <Route
              path={"/" + encrypt("RiskProfiling")}
              element={<RiskProfiling />}
            />
            <Route
              path={"/" + encrypt("ClientReportsComponent") + "/:mode"}
              element={<ClientReportsComponent />}
            />
            <Route
              path={"/" + encrypt("FundAnalyticsComponent") + "/:mode"}
              element={<FundAnalyticsComponent />}
            />
          </>
        )}
        {/***************************** Menu ends for client ********************/}

        {/********************** Menu Starts for RM dashboard *******************/}
        {AuthorizedFunction(["relationship_manager"]) && (
          <>
          {/* Relationship manager Routes */}
         {/* <RelationshipManagerRoute */}
            <Route
              path={"/" + encrypt("Rmdashboard")}
              element={<Rmdashboard />}
            />
            <Route
              path={"/" + encrypt("EnitityReport")}
              element={<EnitityReport />}
            />
            <Route
              path={"/" + encrypt("CorporateReports")}
              element={<CorporateReports />}
            />
            <Route
              path={"/" + encrypt("UploadsComponent") + "/:mode"}
              element={<UploadsComponent />}
            />
            <Route
              path={"/" + encrypt("WatchListModel")}
              element={<WatchListModal />}
            />
            {/* /> */}
          </>
        )}

        {/******************** Menu Ends for RM dashboard ***********************/}

        {/******************** Menu Start for OPeration dashboard ***************/}
        {AuthorizedFunction(["operation_manager"]) && (
          <>
            <Route
              path={"/" + encrypt("Operationdashboard")}
              element={<OperationDashboard />}
            />

            <Route
              path={"/" + encrypt("CreditRatingList")}
              element={<CreditRatingList />}
            />
            <Route
              path={"/" + encrypt("CreditRatingFormComponent") + "/:mode/:id"}
              element={<CreditRatingFormComponent />}
            />
            <Route
              path={"/" + encrypt("SectorList")}
              element={<SectorList />}
            />
            <Route
              path={"/" + encrypt("SectorListFormComponent") + "/:mode/:id"}
              element={<SectorListFormComponent />}
            />
            <Route
              path={"/" + encrypt("InstrumentTypeList")}
              element={<InstrumentTypeList />}
            />
            <Route
              path={"/" + encrypt("InstrumentTypeFormComponent") + "/:mode/:id"}
              element={<InstrumentTypeFormComponent />}
            />
            <Route
              path={"/" + encrypt("InstrumentCategoryList")}
              element={<InstrumentCategoryList />}
            />
            <Route
              path={"/" + encrypt("InstrumentCategoryFormComponent") + "/:mode/:id"}
              element={<InstrumentCategoryFormComponent />}
            />
            <Route
              path={"/" + encrypt("AssetClassList")}
              element={<AssetClassList />}
            />
            <Route
              path={"/" + encrypt("AssetClassFormComponent") + "/:mode/:id"}
              element={<AssetClassFormComponent />}
            />
            <Route
              path={"/" + encrypt("CreateInstrumentLanding")+"/:pageName"}
              element={<CreateInstrumentLanding />}
            />
            <Route
              path={"/" + encrypt("CreateInstrumentFormComponent") + "/:mode/:id"}
              element={<CreateInstrumentFormComponent />}
            />
          </>
        )}

        {/******************** Menu Ends for OPeration dashboard ***************/}

        {/******************** Menu Starts for Default dashboard ***************/}
        <Route
          path={"/" + encrypt("Defaultdashboard")}
          element={<Defaultdashboard />}
        />
        {/********************  Menu Ends for Default dashboard ****************/}

        <Route
          path={"/" + encrypt("DashboardLandingPage")}
          element={<DashboardLandingPage />}
        />

        <Route
          path={"/" + encrypt("Defaultdashboard")}
          element={<Defaultdashboard />}
        />

        <Route
          path="*"
          element={
            <NotFound
              status={404}
              message="Oops! Looks like you're lost in space."
              btnText="Return to Home"
            />
          }
        />
      </Routes>
    </Suspense>
  );
}
