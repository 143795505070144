import {
  faEye,
  faHouse,
  faList,
  faPen,
  faPlane,
  faToggleOn,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../../Component/ComponentsTable/ActionButtons";
import AgTable from "../../../Component/ComponentsTable/AgTable";
import DatefilterParams from "../../../Component/ComponentsTable/agFiler";
import AppModal from "../../../Component/Modal/AppModal";
import Pagehader from "../../../../src/Layout/Pagehader";
import Loader from "../../../util/Loader";
import {
  decryptData,
  encryptData,
  encrypt,
  decrypt,
} from "../../../util/Authenticate/CryptoJS";
import { useParams } from "react-router-dom";
import ButtonComp from "../../../Component/ButtonComp/ButtonComp";
import CreateInstrumentList from "./CreateInstrumentList";
import CreateInstrumentHistory from "./CreateInstrumentHistory";
import FormComponent from "../../Uploads/UploadFormComponent";
import { unstable_batchedUpdates } from "react-dom";

export default function CreateInstrumentLanding() {
  const [rowData, setRowData] = useState();
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [toggleModalOpen, setToggleModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const pageName = decryptData(useParams().pageName);

  useEffect(() => {
    fetchData();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const fetchData = async () => {};

  const getRowStyle = (params) => {
    return {
      backgroundColor: params.node.rowIndex % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternating colors
    };
  };

  const columnDefs = [
    {
      headerName: t("Common:App_lms_Common_00006"),
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      filter: true,
    },
    {
      headerName: t("Common:App_lms_Common_00071"),
      field: "name",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: t("Common:App_lms_Common_00046"),
      field: "description",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: t("Common:App_lms_Common_00060"),
      field: "createdBy",
      minWidth: 210,
      sortable: true,
      filter: true,
    },
    {
      headerName: t("Common:App_lms_Common_00061"),
      field: "approvedOrRejectedByUser",
      minWidth: 210,
      sortable: true,
      filter: true,
    },
    {
      headerName: t("Common:App_lms_Common_00062"),
      field: "approvalDateTime",
      minWidth: 215,
      sortable: true,
      filter: true,
    },
    {
      headerName: t("Common:App_lms_Common_00077"),
      field: "lastModifiedDate",
      sortable: true,
      minWidth: 215,
      filterParams: DatefilterParams,

      cellRenderer: (params) => {
        return moment(params.data.lastModifiedDate).format("DD-MM-YYYY");
      },
    },
    {
      headerName: t("Common:App_lms_Common_00021"),
      field: "status",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "Actions",
      minWidth: 250,
      cellRenderer: (params) =>
        ActionButtonsFunction(params, keycloak, handleTableFunction),
    },
  ];

  const breadcrumbItems = [
    {
      label: t("Common:App_lms_Common_00269"),
      // icon: <FontAwesomeIcon icon={faList} />,
    },
  ];
  //EDIT USER
  const navigate = useNavigate();

  const handleHistoryClick = () => {
    if (pageName === "List") {
      navigate(
        "/" + encrypt("CreateInstrumentLanding") + `/${encryptData("History")}`
      );
    }
    if (pageName === "History") {
      navigate(
        "/" + encrypt("CreateInstrumentLanding") + `/${encryptData("List")}`
      );
    }
  };

  // const handleUploadFile = (fileType) => {
  //   console.log(fileType, "fileType");
  //   if (fileType === "file") {
  //     setTimeout(() => {
  //           unstable_batchedUpdates(() => {
  //             setFile(true);
  //             setInstrument(false);
  //             setOnFileupload("file");
  //           });
  //         }, 500);

  //   } else if (fileType === "instrument") {
  //     setTimeout(() => {
  //       unstable_batchedUpdates(() => {
  //         setInstrument(true);
  //         setFile(false);
  //         setOnFileupload("instrument");
  //       });
  //     }, 500);

  //   }
  // };

  // const handleCloseModal = () => {
  //   setFile(false);
  //   setInstrument(false);
  // };

  // const handleAction = () => {};
  // const handleAddSubmit = (data) => {
  // };

  // const handleFileUpload = async (file) => {
  //   // Maximum file size in bytes (10MB)
  //   const MAX_FILE_SIZE = 10 * 1024 * 1024;
  //   const MIN_FILE_SIZE = 1024;

  //   // Check if the file exceeds the maximum size
  //   if (file.size > MAX_FILE_SIZE) {
  //     alert(
  //       "File size exceeds the maximum limit of 10MB. Please upload a smaller file."
  //     );
  //   } else if (file.size > MIN_FILE_SIZE) {
  //     alert("File size should be at least 1KB. Please upload a larger file.");
  //   }
  //   return; // Stop further execution if the file is too large
  // };

  return (
    <>
      {loading ? (
        <Loader pagename={t("Common:App_lms_Common_00269")} />
      ) : (
        <>
          <Pagehader
            pagename={t("Common:App_lms_Common_00269")}
            Breadcrumbshow={true}
            breadcrumbItems={breadcrumbItems}
          ></Pagehader>
          <div className="pagebody">
            <div className="d-flex gap-3">
              <ButtonComp
                wrapperName={"download_temp_wrapper"}
                type="button"
                btnStyle="box"
                btnText={"Download Format File"}
                btnIcon=""
                onClick={() => {}}
              />
              <ButtonComp
                wrapperName={"download_temp_wrapper"}
                type="button"
                btnStyle="box"
                btnText={"Upload File"}
                btnIcon=""
                onClick={() => {
                  // handleUploadFile("file");
                }}
              />
              <ButtonComp
                wrapperName={"download_temp_wrapper"}
                type="button"
                btnStyle="box"
                btnText={"Download All Instruments"}
                btnIcon=""
                onClick={() => {}}
              />
              <ButtonComp
                wrapperName={"download_temp_wrapper"}
                type="button"
                btnStyle="box"
                btnText={"Update Intruments"}
                btnIcon=""
                onClick={() => {
                  // handleUploadFile("instrument");
                }}
              />
              <ButtonComp
                wrapperName={"download_temp_wrapper"}
                type="button"
                btnStyle="box"
                btnText={pageName == "List" ? "History" : "List"}
                btnIcon=""
                onClick={() => {
                  handleHistoryClick();
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12">
              {pageName === "List" && <CreateInstrumentList />}
              {pageName === "History" && <CreateInstrumentHistory />}
            </div>
          </div>
        </>
      )}
      {/* <AppModal
        isOpen={file || instrument}
        onClose={handleCloseModal}
        handleActon={handleAction} // Ensure this is defined and passed correctly
        ModalTitle={
          file ? "Upload File" : instrument ? "Upload Instrument" : " "
        }
        Modalsize="xl"
        buttonConfigs={[{ text: "Continue", icon: null, action: "continue" }]}
        ModalBody={
          <div className="row ">
            <FormComponent
              onSubmit={handleAddSubmit}
              onFileChangeNew={handleCloseModal}
              downloadTemplate={handleFileUpload} 
              onFileupload={onFileupload}
            />
          </div>
        }
        ModalType="Watchlist"
        ModalScrollable={true}
        // ReactOdometervalue={seconds}
      /> */}
    </>
  );
}
