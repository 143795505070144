import PropTypes from "prop-types"; // Import PropTypes
import React from "react";
import { useTranslation } from "react-i18next";
import "./InputText.scss";

const InputText = (props) => {
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const {
    register,
    errors,
    trigger,
    getValues,
    readOnly = false,
    maxLength = null,
    previewFlag = "",
    type = "text",
    disabled = false,
    onChange = () => {},
    minLength = null,
    registerName,
    mandatory = false,
    labelName = "",
    hidden,
    onPaste,
    onCopy,
    pattern = {},
    ref = null,
    divClassName
  } = props;

  return (
    <div className= {`app_input-wrapper` +" "+ divClassName+" "+ `app_input-wrapper` }>
      <label
        htmlFor={registerName}
        className={errors?.[registerName] ? "app_label errormark" : "app_label"}
      >
        {labelName}
        {mandatory ? <span className="errormark"> *</span> : null}
      </label>

      <input
        onPaste={(e) => {
          if (onPaste) {
            e.preventDefault();
            return true;
          } else e.preventDefault();
          return false;
        }}
        onCopy={(e) => {
          if (onCopy) {
            e.preventDefault();
            return true;
          } else e.preventDefault();
          return false;
        }}
        readOnly={readOnly}
        disabled={disabled}
        ref={ref}
        hidden={hidden}
        type={type}
        autoComplete="off"
        minLength={minLength}
        maxLength={maxLength}
        value={getValues(registerName)}
        placeholder={labelName}
        className={cssFunction(previewFlag, errors, registerName)}
        id={registerName}
        {...register(registerName, {
          required: mandatory
            ? t("Messages:App_lms_Messages_00001", { lable: labelName })
            : false,
          pattern: pattern,
          onChange: (e) => onChange(e),
          onBlur: (e) => onChange(e),
        })}
        // placeholder=""
        onKeyUp={() => {
          trigger(registerName);
        }}
      />
      <span>
      {errors?.[registerName] && (
        <small className="textdanger">{errors?.[registerName]?.message}</small>
      )}
      </span>
    </div>
  );

  function cssFunction(previewFlag, errors, registerName) {
    if (previewFlag) {
      return "app_input previewStyle";
    } else if (errors?.[registerName]) {
      return "app_input errorsborder";
    } else {
      return "app_input";
    }
  }
};
// Prop types validation
InputText.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  previewFlag: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  minLength: PropTypes.number,
  registerName: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  labelName: PropTypes.string,
  hidden: PropTypes.bool,
  onPaste: PropTypes.func,
  onCopy: PropTypes.func,
  pattern: PropTypes.object,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default InputText;
