import { faBell, faCaretDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GetSvglogo } from "../assets/img/app/GetSvglogo";
import { Apiurl } from "../util/apiurl";
import { getLocalStorage } from "../util/Authenticate";
import { encrypt } from "../util/Authenticate/CryptoJS";
import { filtercurrentRole } from "../util/Authenticate/currentRealmRole";
import LogoutButton from "../util/Authenticate/logout";
import { getTenantThemekey } from "../util/Authenticate/TenantMasterconfig";
import axiosInstance from "../util/axiosInstance";
import "./header.scss";

const Header = () => {
  const navigate = useNavigate();
  const [userbox, setUserbox] = useState(false);
  const [notificationbox, setnotificationBox] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    getUserDetails();
  }, []);

  /**
   * Handles user logout by clearing stored data and redirecting to the login page.
   */

  const handleuser = () => {
    setUserbox((prevValue) => !prevValue);
    // setnotificationBox((prevValue) => !prevValuem);
  };
  const handlenotification = () => {
    // setUserbox((prevValue) => !prevValue);

    setnotificationBox((prevValue) => !prevValue);
  };

  const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.userDetails);
      const userData = response.data;
    } catch (error) {
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      setLoading(false);
    }
  };
  const getUserDetailslogin = () => {
    let logingType = filtercurrentRole()[0];
    // console.log(logingType);
    if (logingType === "client") {
      return "Client";
    }
    if (logingType === "relationship_manager") {
      return "R M";
    }
    if (logingType === "operation_manager") {
      return "O M";
    }
    if (logingType === "super_admin") {
      return "Admin";
    }
  };
  return (
    <div className="headerdiv">
      {/* Application Logo */}
      <div className="applogo">
        {/* {getTenantkey("logo")} */}

        <GetSvglogo iconName={getTenantThemekey()} color={"d"}></GetSvglogo>
      </div>

      {/* Navigation Toggle Button */}

      {/* Profile and Logout Section */}
      <div className="d-flex justify-content-end align-items-center me-1">
        <button
          className="btn notification_wall me-1"
          onClick={() => handlenotification()}
        >
          <span className="Popup_notification"> 9+</span>
          <FontAwesomeIcon icon={faBell} size="xl" />
        </button>
        <button className="btn user_wall" onClick={() => handleuser()}>
          <span className="Popup_user_wall">
            {" "}
            <FontAwesomeIcon icon={faUser} />
          </span>

          <span className="Popup_user_roa">
            {getUserDetailslogin()} {}
          </span>
          <FontAwesomeIcon icon={faCaretDown} />
        </button>
      </div>
      {notificationbox && (
        <div className="notification_box  popup_box">
          <ul>
            <li className="notification_div">
              {" "}
              <div className="notification_text">
                notification notific ationnotifica tionnotificationnot
              </div>{" "}
              <div className="notification_time">3 min</div>
            </li>
          </ul>
        </div>
      )}
      {userbox && (
        <div className="user_box popup_box">
          <ul>
            <li>
              {" "}
              <LogoutButton
                logoutType={getLocalStorage(encrypt("Login_Type"))}
                logoutAction={"user"}
              />
              {/* <FontAwesomeIcon icon={faRightFromBracket} /> logout */}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
