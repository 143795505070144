import { faCircle, faCircleDot } from "@fortawesome/free-regular-svg-icons";
import {
  faAdd,
  faBriefcase,
  faNetworkWired,
  faUser,
  faUserGroup,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Clients from "../../../assets/img/Clients.svg";
import Families from "../../../assets/img/Families.svg";
import NoData3 from "../../../assets/img/NoData3.png";
import Prospect from "../../../assets/img/Prospect.svg";
import RMs from "../../../assets/img/RM’s.svg";
import AppbarChart from "../../../Component/ComponentsChart/AppbarChart";
import ApppieChart from "../../../Component/ComponentsChart/ApppieChart";
import AgTable from "../../../Component/ComponentsTable/AgTable";
import { AmountFormatter } from "../../../Component/ComponentsTable/AmountFormatter";
import AppModal from "../../../Component/Modal/AppModal";
import WatchListPage from "../../../Component/Modal/WatchListModel";
import Pagehader from "../../../Layout/Pagehader";
import { Apiurl } from "../../../util/apiurl";
import axiosInstance from "../../../util/axiosInstance";
import Loader from "../../../util/Loader";
import NewsSlider from "./../NewsSlider";
import "./RmDashbord.scss";

export default function Rmdashboard() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("family");
  const [selectedOptioncard, setSelectedOptioncard] = useState("Families");
  const [aumData, setAumData] = useState("");
  const [rmData, setRMData] = useState("");
  const [marketData, setMarketData] = useState([]);
  const [category, setCategory] = useState([]);
  const [aumValueData, setAUMValueData] = useState([]);
  const [clientValueData, setClientValueData] = useState([]);
  const [aumLabels, setAUMLabels] = useState([]);
  const [sipData, setSipData] = useState([]);
  const [months, setMonths] = useState([]);
  const [transactionLabel, setTranscationLabels] = useState([]);
  const [transactionData, setTransationData] = useState([]);
  const [watchListData, setWatchlistData] = useState([]);
  const [fullname, setFullname] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [aumDataLabels, setAumDatalabels] = useState([]);
  const [aumValues, setAumValues] = useState([]);
  
  const [userDetailsId, setuserDetailsId] = useState("");

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    getUserDetails();
    fetchAUMData();
    fetchRMData();
    fetchRelationshipDetailsByClient("family");
    fetchMarketValueBifurcation();
    fetchAUMClientwise();
    fetchSip();
    fetchTransaction();
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.userDetails);
      const userData = response.data;
      setUserId(userData.rmAdvId);
      setuserDetailsId(userData.userId);
      setFullname(userData.fullName);
    } catch (error) {
      console.error("Login error: ", error);
      
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
    // navigate("/" + encrypt("WatchListModel"));
  };
  const handleCloseModal = () => setShowModal(false);

  const handleAction = () => {};

  const handleOptionChange = (event, cardname) => {
    setSelectedOption(event);
    setSelectedOptioncard(cardname);

    fetchRelationshipDetailsByClient(event);
  };
  const [optionbtn, setOptionbtn] = useState("My");
  const handleOptionbtnGro = (event) => {
    // console.log("kk", event);

    setOptionbtn(event);
    if (event == "All") {
      fetchAllRelationshipDetails();
    } else {
      fetchRelationshipDetailsByClient("family");
    }
  };

  // Array of card data
  const cardData = [
    {
      title: "Families",
      iconSrc: Families, // Replace with actual icon path
      value: "family",
      color: "#6076FA",
    },
    {
      title: "Clients",
      iconSrc: Clients,
      value: "client",
      color: "#FA60A9",
    },
    {
      title: "RM's",
      iconSrc: RMs,
      value: "rm",
      color: "#FFC444",
    },
    {
      title: "Prospect",
      iconSrc: Prospect,
      value: "prospect",
      color: "#F36F56",
    },
  ];
  // const [rowData, setRowData] = useState(getData());
  const [rowData, setRowData] = useState([]);
  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
      minWidth: 180,
    },
    {
      headerName: "Current Value",
      field: "currentValue",
      cellRenderer: (params) =>
        AmountFormatterFunction(params.data.currentValue),

      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "AUM Held",
      field: "aumHeld",
      cellRenderer: (params) => AmountFormatterFunction(params.data.aumHeld),
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "AUM Held (Away)",
      field: "aumHeldAway",
      cellRenderer: (params) =>
        AmountFormatterFunction(params.data.aumHeldAway),
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Gain/Loss",
      field: "gainOrLoss",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
  ];

  const breadcrumbItems = [{ label: "dashboard" }];

  const fetchAUMData = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.aumData);
      // toast.success(t("Messages:UploadTemplate"));
      if (!response.statusText == "OK")
        throw new Error("Network response was not ok");
      const result = await response.data;
      setAumData(result);
      const aumLabel = result.data.map((val) => val.type);
      const aumValue = result.data.map((val) => val.value);
      setAumDatalabels(aumLabel);
      let SingleData = [
        {
          label: aumLabel,
          data: aumValue,
          backgroundColor: ["#8A60FA", "#FF7E64", "#FFC444", "#8A60FA"],
          borderWidth: 1,
        },
      ];
      setAumValues(SingleData);
    } catch (error) {
      console.error("Login error: ", error);
      
    } finally {
    }
  };

  const fetchRMData = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.rmDetails);
      // toast.success(t("Messages:UploadTemplate"));
      if (!response.statusText == "OK")
        throw new Error("Network response was not ok");
      const result = await response.data;
      setRMData(result);
    } catch (error) {
      console.error("Login error: ", error);
      
    } finally {
    }
  };



  const fetchRelationshipDetailsByClient = async (type) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.relationshipDetailsByClient}${type}`
      );
      // toast.success(t("Messages:UploadTemplate"));
      if (!response.statusText == "OK")
        throw new Error("Network response was not ok");
      const result = await response.data;
      setRowData(result);
    } catch (error) {
      console.error("Login error: ", error);
      
    } finally {
    }
  };

  const fetchAllRelationshipDetails = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.allRelationshipDetails);
      // toast.success(t("Messages:UploadTemplate"));
      if (!response.statusText == "OK")
        throw new Error("Network response was not ok");
      const result = await response.data;
      setRowData(result);
    } catch (error) {
      console.error("Login error: ", error);
      
    } finally {
    }
  };

  const fetchMarketValueBifurcation = async () => {
    let temp_category_val = [];
    try {
      const response = await axiosInstance.get(Apiurl.marketValueBifurcation);

      const result = response.data;

      // Extract and set categories
      const categories = result.categories.map((item) => item.category);
      setCategory(categories); // Store categories in state

      // Extract market data (marketValue and percentage) and set in state
      const temp_marketData = result.categories.map((item) => item.percentage);
      let SingleData = {
        label: "AUM",
        data: temp_marketData,
        backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
        borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
        borderWidth: 1,
      };
      temp_category_val.push(SingleData);
      setMarketData(temp_category_val);
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };

  const fetchAUMClientwise = async () => {
    let temp_all_aum_data = [];

    try {
      const response = await axiosInstance.get(Apiurl.aumClientwise);

      const result = response.data;
      // console.log("Fetched data:", result);

      // Extract and set categories
      const range = result.data.map((item) => item.range);
      setAUMLabels(range); // Store categories in state

      // Extract market data (marketValue and percentage) and set in state
      const aum_val = result.data.map((item) => item.aumTotal);
      let SingleData = {
        label: "AUM",
        data: aum_val,
        backgroundColor: ["#5FB6FA"],
        borderColor: ["#5FB6FA"],
        borderWidth: 1,
        type: "bar",
        order: 1,
      };

      const client_val = result.data.map((item) => item.clientCount);
      let SingleDataClient = {
        label: "Client",
        data: client_val,
        backgroundColor: ["#00000"],
        borderColor: ["#00000"],
        borderWidth: 1,
        type: "line",
        order: 0,
      };
      // temp_client_val.push(SingleData)
      temp_all_aum_data.push(SingleData, SingleDataClient);
      setClientValueData(temp_all_aum_data);
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };

  const fetchSip = async () => {
    let temp_all_sip_data = [];

    try {
      const response = await axiosInstance.get(Apiurl.sipDetails);

      const result = response.data;
      // console.log("Fetched data:", result);

      // Extract and set categories
      const month = result.categories.map((item) => item);
      setMonths(month); // Store categories in state

      // Extract market data (marketValue and percentage) and set in state
      const aum_val = result.aum.map((item) => item);
      let SingleData = {
        label: "AUM",
        data: aum_val,
        backgroundColor: ["#5FB6FA"],
        borderColor: ["#5FB6FA"],
        borderWidth: 1,
        type: "bar",
        order: 1,
      };

      const client_val = result.clients.map((item) => item);
      let SingleDataClient = {
        label: "Client",
        data: client_val,
        backgroundColor: "#000000",
        borderColor: "#000000",
        borderWidth: 1,
        type: "line",
        order: 0,
      };
      // temp_client_val.push(SingleData)
      temp_all_sip_data.push(SingleData, SingleDataClient);
      setSipData(temp_all_sip_data);
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };

  const fetchTransaction = async () => {
    let temp_transaction_val = [];
    try {
      const response = await axiosInstance.get(Apiurl.transactionGroupByAsset);
      // toast.success(t("Messages:UploadTemplate"));
      if (!response.statusText == "OK")
        throw new Error("Network response was not ok");
      const result = await response.data;
      Object.values(result).map((item) => {
        const transactionLabel = item.transactions.map((val) => val.assetType);
        setTranscationLabels(transactionLabel);

        const net = item.transactions.map((val) => val.net);
        const purchase = item.transactions.map((val) => val.purchase);
        const redemption = item.transactions.map((val) => val.redemption);

        let SingleData = [
          {
            label: "Net",
            data: net,
            backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
            borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
            borderWidth: 1,
          },
          {
            label: "Purchase",
            data: purchase,
            backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
            borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
            borderWidth: 1,
          },
          {
            label: "Redemption",
            data: redemption,
            backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
            borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
            borderWidth: 1,
          },
        ];
        // temp_transaction_val.push(SingleData);
        setTransationData(SingleData);
      });
    } catch (error) {
    } finally {
    }
  };

  // const aumData1 = ["20.99", "10.99", "10.0"];
  // const aumDatalabels = ["Net AUM", "Held AUM", "Held Away AUM"];
  const aumDatabackgroundColor = ["#8B5CF6", "#FCD34D", "#F87171"];

  // const Marketdata = [
  //   {
  //     label: "AUM",
  //     data: [12, 19, 3, 50],
  //     backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", ChartColorsFunction("equity")],
  //     borderWidth: 1,
  //   },
  // ];
  // const MarketDatalabels = ["Commodities", "Hybrid", "Equity", "Debt"];
  // AUMClientWisedata data
  // const AUMClientWisedata = [
  //   {
  //     label: "AUM",
  //     data: [65, -59, 80],
  //     backgroundColor: "#6076FA",
  //     borderColor: "#6076FA",
  //     borderWidth: 1,
  //     type: "bar", // Type set to 'bar'
  //   },
  //   {
  //     label: "Clients",
  //     data: [-5, 59, 80],
  //     backgroundColor: "#000",
  //     borderColor: "#000",
  //     borderWidth: 1,
  //     type: "line", // Type set to 'bar'
  //   },
  // ];
  // const AUMClientWiseDatalabels = ["<10", "10L- 1Cr", ">1Cr"];
  const AUMChartAxis = "x";

  // SIP

  const SIPChartAxis = "x";
  // Transactions

  const Transactionsdata = [
    {
      label: "Net",
      data: [0, 59, 100, 50],
      backgroundColor: "#5FB6FA",
      borderColor: "#5FB6FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Purchase",
      data: [0, 59, 100, 50],
      backgroundColor: "#5FF6FA",
      borderColor: "#5FF6FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Redemption",
      data: [0, 59, 0, 50],
      backgroundColor: "#6076FA",
      borderColor: "#6076FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
  ];
  const TransactionsDatalabels = ["Commodation", "Debt", "Equity", "Hybrid"];
  const TransactionsChartAxis = "y";
  return (
    <>
      {loading ? (
        <Loader pagename="Dashboard" />
      ) : (
        <>
          {" "}
          <Pagehader
            pagename="Dashboard"
            getfullName={fullname}
            Breadcrumbshow={true}
            breadcrumbItems={breadcrumbItems}
          ></Pagehader>
          <div className="pagebody">
            <div className="row mb-3">
              <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                <div className="card rmdashbordcard AUMcard">
                  <div className="card-body dashbordcardTitle">
                    <p className="chartname mb-0">AUM</p>
                    <div className="aumchart">
                      {/* <SemiDoughnutChart
                        ChartData={aumValues}
                        ChartDatalabels={aumDataLabels}
                        ChartDatabackgroundColor={aumDatabackgroundColor}
                        cutoutPercentage={"60%"}
                      /> */}
                      <ApppieChart
                        type="DoughnutChart"
                        legendposition="bottom"
                        legenddisplay={true}
                        Chartdata={aumValues}
                        values={aumValues}
                        Chartlabels={aumDataLabels}
                        isSemiDoughnut={true}
                      />
                    </div>
                    {/* <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                      <img
                        className="noData img-fluid"
                        src={NoData3}
                        alt="No data found"
                      ></img>
                    </div> */}
                  </div>
                </div>
                <div className="d-flex ">
                  <div className="card totalcard rmdashbordcard">
                    <div className="card-body dashbordcardTitle p-2">
                      <p className="totalcardtext">Total Families</p>
                      <div className="divvalue">
                        <FontAwesomeIcon icon={faUserGroup} />
                        <span>
                          {aumData?.totalFamily == 0 ||
                          aumData?.totalFamily == null
                            ? 0
                            : aumData?.totalFamily}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card totalcard rmdashbordcard">
                    <div className="card-body dashbordcardTitle  p-2">
                      <p className="totalcardtext">Total Clients</p>
                      <div className="divvalue">
                        <FontAwesomeIcon icon={faUser} />
                        <span>
                          {aumData?.totalClients == 0 ||
                          aumData?.totalClients == null
                            ? 0
                            : aumData?.totalClients}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <div className="card  dashbordcard AssetsValuecard">
                  <div className="card-body dashbordcardTitle AssetsValue">
                    <div className="AssetsValuediv">
                      <div className="icon">
                        <FontAwesomeIcon icon={faNetworkWired} />
                      </div>
                      <div className="Assetstext">
                        <p className="Assetstext_name">RM Under me</p>
                        <p className="Assetstext_value">
                          {rmData?.rmUnderMeCount == 0 ||
                          rmData?.rmUnderMeCount == null
                            ? 0
                            : rmData?.rmUnderMeCount}
                        </p>
                      </div>
                    </div>

                    <div className="AssetsValuediv">
                      <div className="icon">
                        <FontAwesomeIcon icon={faBriefcase} />
                      </div>
                      <div className="Assetstext">
                        <p className="Assetstext_name">New Revenue (MTD)</p>
                        <p className="Assetstext_value">
                          {rmData?.totalNetRevenue == 0 ||
                          rmData?.totalNetRevenue == null
                            ? 0
                            : rmData?.totalNetRevenue}
                        </p>
                      </div>
                    </div>

                    <div className="AssetsValuediv">
                      <div className="icon">
                        <FontAwesomeIcon icon={faWallet} />
                      </div>
                      <div className="Assetstext">
                        <p className="Assetstext_name">
                          Net New Assets (MTD) (Held)
                        </p>
                        <p className="Assetstext_value">
                          {rmData?.totalNetNewAssets == 0 ||
                          rmData?.totalNetNewAssets == null
                            ? 0
                            : rmData?.totalNetNewAssets}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-5 col-xl-5">
                <div className="card  dashbordcard newscard">
                  <div className="card-body p-0">
                    <NewsSlider addcss={"newscard"} type={"rm"} />
                  </div>
                </div>
                <div className="card  dashbordcard Watchlistcard">
                  <div className="card-body dashbordcardTitle WatchlistcardValue">
                    {/* AddWatchlist */}
                    <div className="WatchlistcardValuediv">
                      <p className="Watchlistcardtext_name">Add Watchlist</p>
                      <button
                        type="button"
                        className="btn Watchlistcardtext_btn"
                        onClick={handleOpenModal}
                      >
                        <FontAwesomeIcon icon={faAdd} />
                      </button>
                      {/* <WatchListModal show={showModal} handleClose={handleCloseModal} stocks={watchlist} /> */}
                      {/* <WatchListPage show={showModal} stocks={watchlist} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3 mincards">
              <div className="col-12">
                <div className="btnGro">
                  <button
                    className={
                      optionbtn === "My"
                        ? "My_all_btn btn actbtn"
                        : "My_all_btn btn"
                    }
                    onClick={() => handleOptionbtnGro("My")}
                  >
                    My
                  </button>
                  <button
                    className={
                      optionbtn === "All"
                        ? "My_all_btn btn actbtn"
                        : "My_all_btn btn"
                    }
                    onClick={() => handleOptionbtnGro("All")}
                  >
                    {" "}
                    All
                  </button>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              {optionbtn === "My" ? (
                <>
                  {cardData.map((card) => (
                    <div className={`col-12 col-md-3 mb-4`} key={card.value}>
                      <button
                        className={`buttonGrocard `}
                        onClick={() =>
                          handleOptionChange(card.value, card.title)
                        }
                      >
                        <div
                          className={
                            card.value === selectedOption
                              ? `card dashbordcard btnGrocard active` +
                                selectedOption
                              : " card dashbordcard btnGrocard"
                          }
                        >
                          <div className="card-body dashbordcardTitle btnGrocardTitle p-2">
                            <div className="btnGrocardtext">
                              <div
                                className="icon"
                                style={{ color: `${card.color}` }}
                              >
                                {card.value === selectedOption ? (
                                  <FontAwesomeIcon icon={faCircleDot} />
                                ) : (
                                  <FontAwesomeIcon icon={faCircle} />
                                )}
                              </div>
                              <div
                                className="text"
                                style={{ color: `${card.color}` }}
                              >
                                {card.title}
                              </div>
                            </div>
                            <div className="btnGrovalue">
                              <img src={card.iconSrc} />
                              {/* <FontAwesomeIcon
                                icon={card.iconSrc}
                                style={{ color: `${card.color}` }}
                                size="2xl"
                              /> */}
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <div
                  className={
                    `card rmdashbordcard Informationcard active` +
                    selectedOption
                  }
                >
                  {/* {console.log("test1", selectedOptioncard, selectedOption)} */}
                  <div className="card-body dashbordcardTitle InformationcardValue">
                    {rowData.length == 0 ? (
                      <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                        <img
                          className="noData img-fluid"
                          src={NoData3}
                          alt="No data found"
                        ></img>
                      </div>
                    ) : (
                      <div className="InformationTable">
                        <AgTable
                          columnKeys={columnDefs.field}
                          columnDefs={columnDefs}
                          rowData={rowData}
                          filenames={
                            `${optionbtn}` +
                            "_" +
                            `${optionbtn === "All" ? "" : selectedOptioncard}` +
                            "_" +
                            "Information"
                          }
                          StyleClass={"ag_export_btn ripple_btn"}
                          downloadbtnstext={true}
                          // onSelectionChanged={onSelectionChangedContribution}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Client Table */}

            {/* Charts Section */}
            <div className="row">
              {/* Product Market Value and Asset Market Value Bifurcation */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      Product Market Value and Asset Market Value Bifurcation
                    </p>

                    <div className="dashbordchart mixbar">
                      {marketData.length == 0 || category.length == 0 ? (
                        <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                          <img
                            className="noData img-fluid"
                            src={NoData3}
                            alt="No data found"
                          ></img>
                        </div>
                      ) : (
                        <ApppieChart
                          type={"DoughnutChart"}
                          legendposition={"bottom"}
                          legenddisplay={true}
                          Chartdata={marketData}
                          Chartlabels={category}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* AUM Client Wise (In Cr.)*/}
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      AUM Client Wise (In Cr.)
                    </p>
                    <div className="dashbordchart mixbar">
                      <AppbarChart
                        legendposition={"bottom"}
                        legenddisplay={true}
                        Chartdata={clientValueData}
                        Chartlabels={aumLabels}
                        ChartAxis={AUMChartAxis}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Transactions grouped by Assets (In Cr.) */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      Transactions grouped by Assets (In Cr.)
                    </p>
                    <div className="dashbordchart mixbar">
                      {transactionLabel && transactionData ? (
                        <AppbarChart
                          legendposition={"bottom"}
                          legenddisplay={true}
                          Chartdata={transactionData}
                          Chartlabels={transactionLabel}
                          ChartAxis={TransactionsChartAxis}
                        />
                      ) : (
                        <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                          <img
                            className="noData img-fluid"
                            src={NoData3}
                            alt="No data found"
                          ></img>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* {console.log("first", sipData)} */}
              {/* SIP Details*/}
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">SIP Details</p>
                    <div className="dashbordchart mixbar">
                      {months.length > 0 ? (
                        <AppbarChart
                          legendposition={"bottom"}
                          legenddisplay={true}
                          Chartdata={sipData}
                          Chartlabels={months}
                          ChartAxis={SIPChartAxis}
                        />
                      ) : (
                        <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                          <img
                            className="noData img-fluid"
                            src={NoData3}
                            alt="No data found"
                          ></img>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </>
      )}
      <AppModal
        isOpen={showModal}
        onClose={handleCloseModal}
        handleActon={handleAction} // Ensure this is defined and passed correctly
        ModalTitle="Watch List"
        Modalsize="xl"
        buttonConfigs={[{ text: "Continue", icon: null, action: "continue" }]}
        ModalBody={
          // <p>You have been idle for some time. Do you want to continue?</p>
          <div className="row ">
            <WatchListPage userId={userDetailsId} />
          </div>
        }
        ModalType="Watchlist"
        ModalScrollable={true}
        // ReactOdometervalue={seconds}
      />
    </>
  );
}
function AmountFormatterFunction(params) {
  return <AmountFormatter amount={params} />;
}
