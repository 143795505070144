export const Apiurl = {
  interventionlist: `admin/api/v1/lms-admin/intervention/list`,
  interventionCreate: `admin/api/v1/lms-admin/intervention/create`,
  interventionSingleView: `admin/api/v1/lms-admin/intervention/getById`,
  interventionEdit: `admin/api/v1/lms-admin/intervention/edit`,
  interventionToggle: `admin/api/v1/lms-admin/intervention/toogle`,
  schoolList: `master/api/v1/lms-master/school-mgmt/list`,
  activatedUserRoleList: `user/api/v1/usertype/list`,
  authlogin: "auth/login",
  authrefreshogin: "auth/refresh",

  //app login
  api_login: "redirect/api/v1/repo/auth/login",
  userDetails : 'redirect/api/v1/repo/user/getUser',
  downloadExcelTemplate:"redirect/api/v1/repo/bulk/downloadExcelTemplate",
  clientMstOnboardingExcelTemplate:"redirect/api/v1/repo/bulk/clientMstOnboardingExcelTemplate",
  uploadExcelTemplate: "redirect/api/v1/repo/bulk/upload/excel",
  bulkHistory : 'redirect/api/v1/repo/bulk/download/',
  generateReportHolding : "redirect/api/v1/repo/report/generateHoldingReport",
  generateReportCapitalGain : 'redirect/api/v1/repo/report/generateCapitalGainReport',
  generateReportTransaction : 'redirect/api/v1/repo/report/generateTransactionReport',
  generateReportSecurity : 'redirect/api/v1/repo/report/generateSecurityReport',
  generateReportXirr : 'redirect/api/v1/repo/report/generateXirrReport',
  generateFamilyOfficeReport : "redirect/api/v1/repo/report/generateFamilyOfficeReport",
  downloadReport: "redirect/api/v1/repo/report/downloadReport?",
  checkIfReportIsGenerated:"redirect/api/v1/repo/report/checkIfReportIsGenerated?",
  viewReport : 'redirect/api/v1/repo/report/viewReport?',
  generateviewReport : 'redirect/api/v1/repo/report/view/report?',
  
  // http://localhost:10001/redirect/api/v1/repo/report/view/report?reportType=HOLDING_REPORT&fileId=b302265c-97ec-4979-b09b-45cb7da3558b

  //RM Dashboard
  aumData : 'redirect/api/v1/rm/dashboard/AumChartData',
  rmDetails : 'redirect/api/v1/rm/dashboard/rmDetails',
  allRelationshipDetails : 'redirect/api/v1/rm/dashboard/rmAllRelationshipdetails',
  marketValueBifurcation : '/redirect/api/v1/rm/dashboard/marketValuebifurcation',
  aumClientwise : 'redirect/api/v1/rm/dashboard/Aumclientwise',
  sipDetails : 'redirect/api/v1/rm/dashboard/getSipDetails',
  transactionGroupByAsset : 'redirect/api/v1/rm/dashboard/transactionsGroupedByAssets',
  relationshipDetailsByClient : 'redirect/api/v1/rm/dashboard/rmRelationshipdetailsByType?type=',
  watchListDD : 'redirect/api/v1/rm/dashboard/watchListDropdown',

  saveWatchList : 'redirect/api/v1/rm/dashboard/saveWatchlist',
  getWatchlistbyid : 'redirect/api/v1/rm/dashboard/getWatchlist?watchlist=',
  watchListSearch :'redirect/api/v1/rm/dashboard/watchListDropdownwithSearch?searchTerm=',
  //watchlist=WATCHLIST4
  removeWatchlist:'redirect/api/v1/rm/dashboard/removeWatchlist/',
  getAllWatchlist:'/redirect/api/v1/rm/dashboard/getAllWatchlist',
  

  //Client Dashboard
  investmentChartData : 'redirect/api/v1/repo/clientdashboard/getInvestmentChartData/',
  allHolding :  'redirect/api/v1/repo/clientdashboard/getAllHoldingsByClient/',
  combineHolding : 'redirect/api/v1/repo/clientdashboard/getHoldingsCombineUnderInstrument/',
  holdingsProductList: 'redirect/api/v1/repo/clientdashboard/getListOfInstrumentUnderProduct/',
  allAssetAllocation : 'redirect/api/v1/repo/clientdashboard/getAllAssetAllocationByClient/',
  topHoldings : 'redirect/api/v1/repo/clientdashboard/getTopHoldingsByClient/',
  productAllocation : 'redirect/api/v1/repo/clientdashboard/getProductAllocationByClient/',
  securityExposure : 'redirect/api/v1/repo/clientdashboard/getSecurityExposure/',
  assetAllocationChart: 'redirect/api/v1/repo/clientdashboard/getAssetAllocationChartSinceInception/',
  sectorExposure : 'redirect/api/v1/repo/clientdashboard/getSectorExposure/',
  mutualFundAMCExposure : 'redirect/api/v1/repo/clientdashboard/getAmcChart/',
  marketCap : 'redirect/api/v1/repo/clientdashboard/getMarketCapChart/',
  portfolioXirr : 'redirect/api/v1/repo/clientdashboard/getPortfolioXirr/',


  //report filter
  allFamily : 'redirect/api/v1/repo/mst/getAllFamilys/',
  allClientByFamily : 'redirect/api/v1/repo/mst/getAllClientsByFamilyId/',
  allAccountCategoryByClient : 'redirect/api/v1/repo/mst/getCategoryByClientId/',
  allAccountByClient : 'redirect/api/v1/repo/mst/getAccountsByclientId/',
  allAccountByClientReport : 'redirect/api/v1/repo/mst/getAccountsByclientIds/',
  getProduct : 'redirect/api/v1/repo/master/fetchMasterProductList',
  

 
  CorporateReportspast : `redirect/api/v1/repo/report/view/past/history`,
  CorporateReportsrecent : `redirect/api/v1/repo/report/view/recent/history`,
  uploadHistory : 'redirect/api/v1/repo/bulk/view/bulkUpload/history?fileUploadType=',
  excelDownload : 'redirect/api/v1/repo/bulk/download/',
  reportHistory : 'redirect/api/v1/repo/report/view/history'

};
