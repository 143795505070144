export const TenantMaster = [
  {
    tenantId: 1,
    tenantName: "axis",
    tenantHost: "localhost",
    tenantThemeName: "default",
  },

  {
    tenantId: 2,
    tenantName: "axis",
    tenantHost: "wealthdemo.finlabsindia.org",
    tenantThemeName: "default",
  },
  {
    tenantId: 3,
    tenantName: "axis",
    tenantHost: "bank1.fiscus.in",
    tenantThemeName: "axis",
  },
 
];
