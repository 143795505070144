import { jwtDecode } from "jwt-decode"; // Ensure proper import syntax
import { encrypt, encryptData } from "./CryptoJS";
import { faArrowDown, faArrowUp, faCaretDown, faCaretUp, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const DEFAULT_IDLE_TIME_MINUTES = 10; // Default idle timer in minutes
// Configurable Constants
const CONFIG = {
  TOKEN_KEY: encrypt("jwt-secret-app"),
  ACCESS_KEY: encrypt("jwt-access"),
  LOGIN_TYPE_KEY: encrypt("Login_Type"),
  IDLE_TIMER_KEY: encrypt("idletimer"),
  Counter: encrypt("counter"),
  DEFAULT_IDLE_TIME: DEFAULT_IDLE_TIME_MINUTES * 60 * 1000, // Convert minutes to milliseconds
  DELAY_TIME_MS: 3000, // in milliseconds (60 seconds)
  // DEFAULT_IDLE_TIME: 600000, // Default idle timer in milliseconds (60 seconds)
  // DELAY_TIME_MS: 900000, // in milliseconds (60 seconds)
};

let currentAccessToken = "";

/**
 * Logs in the user and stores relevant details in localStorage.
 */
export const login = (accessToken, logtype) => {
  currentAccessToken = accessToken;
  // Store encrypted data in localStorage
  setLocalStorage(CONFIG.TOKEN_KEY, accessToken);
  setLocalStorage(CONFIG.ACCESS_KEY, encryptData("true"));

  setLocalStorage(CONFIG.LOGIN_TYPE_KEY, encryptData(logtype));
  setLocalStorage(CONFIG.Counter, encryptData("1"));
  
  return validateJwt(accessToken);
};

/**
 * Validates the JWT for expiration and structure.
 */
export const validateJwt = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

    // Check expiration
    return decoded.exp && decoded.exp > currentTime;
  } catch (error) {
    console.error("Invalid JWT:", error.message);
    return false;
  }
};

/**Retrieves the user ID from the JWT.*/
export const getUserId = () => {
  const token = getLocalStorage(CONFIG.TOKEN_KEY);
  return token ? jwtDecode(token).sub : null;
};

/**Retrieves the idle timer value.*/
export const getIdleTimer = () => {
  const token = getLocalStorage(CONFIG.TOKEN_KEY);
  return token ? encryptData(600000) : encryptData(600000);
};

/** Retrieves the JWT from localStorage.*/
export const getToken = () => getLocalStorage(CONFIG.TOKEN_KEY);

/** Retrieves the login type from localStorage.
 */
export const getLoginType = () => getLocalStorage(CONFIG.LOGIN_TYPE_KEY);

/**Retrieves the JWT expiration time.*/
export const getTokenExpiration = () => {
  const token = getLocalStorage(CONFIG.TOKEN_KEY);
  return token ? jwtDecode(token).exp : null;
};

/**Retrieves the JWT issued-at time.*/
export const getTokenIssuedAt = () => {
  const token = getLocalStorage(CONFIG.TOKEN_KEY);
  return token ? jwtDecode(token).iat : null;
};

/** Retrieves the tenant information from the JWT.*/
export const getUserTenant = () => {
  const token = getLocalStorage(CONFIG.TOKEN_KEY);
  return token ? jwtDecode(token).tenant : null;
};

/**Retrieves the access value from localStorage.*/
export const getAccess = () => getLocalStorage(CONFIG.ACCESS_KEY);

/** Removes the access value from localStorage.*/
export const removeAccess = () => removeLocalStorage(CONFIG.ACCESS_KEY);

/**Removes the JWT from localStorage.*/
export const removeToken = () => removeLocalStorage(CONFIG.TOKEN_KEY);

/**Removes the login type from localStorage.*/
export const removeLoginType = () => removeLocalStorage(CONFIG.LOGIN_TYPE_KEY);

/** Returns the delay time in milliseconds.*/
export const getDelayTime = () => CONFIG.DELAY_TIME_MS;

/** Logs out the user and updates access information.*/

/**
 * Helper to set data in localStorage.
 */
export const setLocalStorage = (key, value) => {
  if (key && value) {
    localStorage.setItem(key, value);
  }
};

/**
 * Helper to get data from localStorage.
 */
export const getLocalStorage = (key) => {
  return key ? localStorage.getItem(key) : null;
};

/**
 * Helper to remove data from localStorage.
 */
export const removeLocalStorage = (key) => {
  if (key) {
    localStorage.removeItem(key);
  }
};

export const negativeFormatter = (value) => {
  const numericValue = parseFloat(value);
  // Check if the value is negative and remove the "-" sign
  const isNegative = numericValue < 0;
  const absoluteValue = Math.abs(numericValue);
  const iconClass = isNegative ? "negativeClass" : "positiveClass";
  const icon = isNegative ? faCaretDown : faCaretUp;
  return {absoluteValue, iconClass, icon}; 
};

export const ChartColorsFunction = (color) => {
  const fetchedColor = JSON.parse(getLocalStorage(encrypt("ChartColors")));
  const filteredValues = Object.entries(fetchedColor)
    .filter(([objKey]) => objKey.includes(color)) // Filter entries based on the key
    .map(([, value]) => value); // Extract only the values
  return filteredValues[0];
};