import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import "../../App.scss";
import ButtonComp from "../../Component/ButtonComp/ButtonComp";
import Pagehader from "../../Layout/Pagehader";
import { Apiurl } from "../../util/apiurl";
import axiosInstance from "../../util/axiosInstance";
import Loader from "../../util/Loader";
import AppToaster from "../../util/Toaster/AppToaster";
import { DownloadReportPDFFunction } from "../Uploads/UploasAction";
import LoadFromBase64pdf from "./../../util/LoadFromBase64Example";
import { EntityReportsMaster } from "./EntityReportMaster";
import { encrypt } from "../../util/Authenticate/CryptoJS";

export default function EnitityReport() {
  // EntityMaster JSON
  const EntityMaster = EntityReportsMaster;

  const [reportBy, setReportBy] = useState("Family");
  const [reportType, setReportType] = useState("Both");
  const [activeAccordion, setActiveAccordion] = useState(null); // Track open accordion
  const [radioSelections, setRadioSelections] = useState({}); // Store radio button selections for each accordion
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [rmId, setRmId] = useState("");
  const [family, setFamily] = useState([]);
  const [client, setClient] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedAccountCategory, setSelectedAccountCategory] = useState("");
  const [accountCategory, setAccountCategory] = useState([]);
  const [account, setAccount] = useState([]);
  const [product, setProduct] = useState([]);
  const [reportFormat, setReportFormat] = useState([
    {
      value: 1,
      label: "PDF",
    },
  ]);

  const [handleAction, setHandleAction] = useState(null);
  const [viewReportPDF, setViewReportPDF] = useState(null);

  const handleRadioChange = (value) => {
    setReportBy(value);
    setActiveAccordion(null);
    setRadioSelections({});
  };

  const handleReportTypeChange = (value) => {
    setReportType(value);
  };

  const toggleAccordion = (key) => {
    setActiveAccordion(activeAccordion === key ? null : key); // Toggle accordion
  };

  const handleSelection = (key, value) => {
    // console.log("Clicked", key, value);
    if (key == "family") {
      fetchAllClientByRm(value);
    }
    if (key == "client") {
      setSelectedClientId(value);
      fetchAllAccountCategoryByClient(value);
    }
    if (key == "accountCategory") {
      setSelectedAccountCategory(value);
      fetchAllAccounByClient(selectedClientId, value);
    }
    setRadioSelections({
      ...radioSelections,
      [key]: value,
    });
  };

  // console.log("Clicked", selectedClientId);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    getUserDetails();
    fetchProduct();
  }, []);

  const breadcrumbItems = [
    {
      label: t("Common:App_lms_Common_00119"),
      href: "/" + encrypt("Rmdashboard"),
    },
    {
      label: t("Common:App_lms_Common_00248"),
    },
  ];

  const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.userDetails);
      const userData = response.data;
      fetchAllFamily(userData.rmAdvId);
      fetchAllClientByRm(userData.rmAdvId);
    } catch (error) {
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      setLoading(false);
    }
  };

  const fetchAllFamily = async (id) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${Apiurl.allFamily}${id}`);

      setLoading(false);
      let result = response.data;
      // console.log("item", result);

      setFamily([]);
      Object.values(result).map((item) => {
        let SingleData = {
          label: item.name,
          value: item.id,
        };
        setFamily((prevData) => [...prevData, SingleData]);
      });
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const fetchAllClientByRm = async (id) => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(
        `${Apiurl.allClientByFamily}${id}`
      );

      setLoading(false);
      let result = response.data;
      Object.values(result).map((item) => {
        let SingleData = {
          label: item.clinetName,
          value: item.id,
        };
        setClient((prevData) => [...prevData, SingleData]);
      });
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const fetchAllAccountCategoryByClient = async (id) => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(
        `${Apiurl.allAccountCategoryByClient}${id}`
      );

      setLoading(false);
      let result = response.data;
      setAccountCategory([]);
      Object.values(result).map((item) => {
        let SingleData = {
          label: item,
          value: item,
        };
        setAccountCategory((prevData) => [...prevData, SingleData]);
      });
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const fetchAllAccounByClient = async (id, category) => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(
        `${Apiurl.allAccountByClient}${id}/${category}`
      );

      setLoading(false);
      let result = response.data;
      setAccount([]);
      Object.values(result).map((item) => {
        let SingleData = {
          label: item.name,
          value: item.id,
        };
        setAccount((prevData) => [...prevData, SingleData]);
      });
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const fetchProduct = async () => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(Apiurl.getProduct);

      setLoading(false);
      let result = response.data;
      setProduct([]);
      Object.values(result).map((item) => {
        let SingleData = {
          label: item.productName,
          value: item.id,
        };
        setProduct((prevData) => [...prevData, SingleData]);
      });
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const handleExport = useCallback((pageName, userAction) => {
    setHandleAction(null);
    setViewReportPDF(null);
    setHandleAction(userAction);
    handleReportsExport(pageName);
  }, []);
  const handleReportsExport = async (pageName) => {
    setData(null);

    let url;
    if (pageName == "Holding Reports") {
      url = Apiurl.generateReportHolding;
    } else if (pageName == "Capital Gain Reports") {
      url = Apiurl.generateReportCapitalGain;
    } else if (pageName == "Security Reports") {
      url = Apiurl.generateReportSecurity;
    } else if (pageName == "XIRR") {
      url = Apiurl.generateReportXirr;
    } else if (pageName == "Family Office") {
      url = Apiurl.generateFamilyOfficeReport
    }
    setLoading(true);
    let payload = {
      fileName: "",
      message: "",
    };
    try {
      const response = await axiosInstance.post(url, payload);
      if (response) {
        // setLoading(false);
        toast.success(response.data.message);
        <AppToaster duration={50000} Toastericon={"👏"} />;
        setData(response.data.reportFileId);

        fetchData();
        // DownloadReportPDFFunction("HOLDING_REPORT", "28b50983-e111-44a0-907a-dc6204cfe4f3");
      }
    } catch (error) {
      // setLoading(false);
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      // setLoading(false);
    }
  };

  // Callback function for polling
  const fetchData = useCallback(() => {
    // Simulate the API call by using a function like GeneratedReportPDFFunction
    let temradioSelections =
      radioSelections.report == "Holding Reports"
        ? "HOLDING_REPORT"
        : radioSelections.report == "Capital Gain Reports"
        ? "CAPITAL_GAIN_REPORT"
        : radioSelections.report == "Security Reports"
        ? "SECURITY_REPORT"
        : radioSelections.report == "XIRR"
        ? "XIRR_REPORT"
        : radioSelections.report == "Family Office" 
        ? "FAMILY_OFFICE_REPORT" : "";
    if (data !== null) {
      GeneratedReportPDFFunction(temradioSelections, data);
    }
    // Check if the response is true
    if (isSuccess === true) {
      // setIsSuccess(true); // Stop polling if response is true
      if (handleAction === "export") {
        setHandleAction(null);
        setViewReportPDF(null);
        DownloadReportPDFFunction(temradioSelections, data);
      }
      if (handleAction === "view") {
        setHandleAction(null);
        setViewReportPDF(null);
        ViewReportPDFFunction(temradioSelections, fileId);
      }

      setLoading(false);
    } else {
      setIsSuccess(false); // Continue polling if response is not true
      setLoading(true);
    }
  }, [data, handleAction]); // Include `data` as a dependency if it changes

  useEffect(() => {
    // Start polling with setInterval (runs every 3 seconds)
    const intervalId = setInterval(fetchData, 3000);

    // Clear the interval if polling is successful
    if (isSuccess) {
      clearInterval(intervalId);
    } else {
      if (data == null) {
        clearInterval(intervalId);
      }
    }

    // Cleanup function to clear interval on component unmount or when polling stops
    return () => clearInterval(intervalId);
  }, [fetchData]); // Depend on `fetchData` and `isSuccess`

  const GeneratedReportPDFFunction = async (reportType, fileId) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.checkIfReportIsGenerated}reportType=${reportType}&fileId=${fileId}`
      );
      if (response.data) {
        // Continue polling if response is not true

        if (handleAction === "export") {
          setHandleAction(null);
          setViewReportPDF(null);
          DownloadReportPDFFunction(reportType, fileId);
        }
        if (handleAction === "view") {
          setHandleAction(null);
          setViewReportPDF(null);
          ViewReportPDFFunction(reportType, fileId);
        }
        setLoading(false);
        setData(null);
        setIsSuccess(false);
      }
      setIsSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("Download error: ", error);
    }
  };

  const ViewReportPDFFunction = async (reportType, fileId) => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(
        `${Apiurl.generateviewReport}reportType=${reportType}&fileId=${fileId}`
      );
      let result = response.data;
      // console.log("response.data", result);
      toast.success("");
      <AppToaster duration={50000} Toastericon={"👏"} />;
      setViewReportPDF(result);
      setLoading(false);
    } catch (error) {
      console.error("Download error: ", error);
    }
  };
  return (
    <>
      {loading ? (
        <Loader pagename="EReports " />
      ) : (
        <>
          <Pagehader
            pagename="EReports"
            Breadcrumbshow={true}
            breadcrumbItems={breadcrumbItems}
          />
          <div>
            <div className="pagebody Reportspagebody">
              <div style={styles.container}>
                <h3>Reports by</h3>
                <div style={styles.radioGroup}>
                  {Object.keys(EntityMaster).map((type) => (
                    <label key={type} style={styles.radioLabel}>
                      <span> </span>
                      <input
                        className="me-1"
                        type="radio"
                        value={type}
                        checked={reportBy === type}
                        onChange={() => handleRadioChange(type)}
                      />
                      {type}
                    </label>
                  ))}
                </div>

                {EntityMaster[reportBy].option.map((item, index) => (
                  <div key={index} style={styles.accordionContainer}>
                    <div
                      onClick={() => toggleAccordion(item.value)}
                      style={styles.accordionHeader}
                    >
                      {item.labelName}
                      <span>
                        {activeAccordion === item.value ? (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleDown} />
                        )}
                      </span>
                    </div>
                    {activeAccordion === item.value && (
                      <div style={styles.accordionContent}>
                        {item.labelName === "Select Report"
                          ? EntityMaster[reportBy].radioOptions.map(
                              (option) => (
                                <div
                                  key={option}
                                  style={{
                                    ...styles.dropdownOption,
                                    backgroundColor:
                                      radioSelections[item.value] === option
                                        ? "#e0eaff" // Highlight color
                                        : "transparent", // Default background
                                  }}
                                  onClick={() =>
                                    handleSelection(item.value, option)
                                  }
                                >
                                  {option}
                                </div>
                              )
                            )
                          : item.labelName === "Select Family"
                          ? family.map((option) => (
                              <div
                                key={option}
                                style={{
                                  ...styles.dropdownOption,
                                  backgroundColor:
                                    radioSelections[item.value] === option.value
                                      ? "#e0eaff" // Highlight color
                                      : "transparent", // Default background
                                }}
                                onClick={() =>
                                  handleSelection(item.value, option.value)
                                }
                              >
                                {option.label}
                              </div>
                            ))
                          : item.labelName === "Select Client"
                          ? client.map((option) => (
                              <div
                                key={option.value}
                                style={{
                                  ...styles.dropdownOption,
                                  backgroundColor:
                                    radioSelections[item.value] === option.value
                                      ? "#e0eaff" // Highlight color
                                      : "transparent", // Default background
                                }}
                                onClick={() =>
                                  handleSelection(item.value, option.value)
                                }
                              >
                                {option.label}
                              </div>
                            ))
                          : item.labelName === "Select Account Category"
                          ? accountCategory.map((option) => (
                              <div
                                key={option.value}
                                style={{
                                  ...styles.dropdownOption,
                                  backgroundColor:
                                    radioSelections[item.value] === option.label
                                      ? "#e0eaff" // Highlight color
                                      : "transparent", // Default background
                                }}
                                onClick={() =>
                                  handleSelection(item.value, option.label)
                                }
                              >
                                {option.label}
                              </div>
                            ))
                          : item.labelName === "Select Account"
                          ? account.map((option) => (
                              <div
                                key={option.value}
                                style={{
                                  ...styles.dropdownOption,
                                  backgroundColor:
                                    radioSelections[item.value] === option.value
                                      ? "#e0eaff" // Highlight color
                                      : "transparent", // Default background
                                }}
                                onClick={() =>
                                  handleSelection(item.value, option.value)
                                }
                              >
                                {option.label}
                              </div>
                            ))
                          : item.labelName === "Select Product"
                          ? product.map((option) => (
                              <div
                                key={option.value}
                                style={{
                                  ...styles.dropdownOption,
                                  backgroundColor:
                                    radioSelections[item.value] === option.value
                                      ? "#e0eaff" // Highlight color
                                      : "transparent", // Default background
                                }}
                                onClick={() =>
                                  handleSelection(item.value, option.value)
                                }
                              >
                                {option.label}
                              </div>
                            ))
                          : item.labelName === "Select Report Format"
                          ? reportFormat.map((option) => (
                              <div
                                key={option.value}
                                style={{
                                  ...styles.dropdownOption,
                                  backgroundColor:
                                    radioSelections[item.value] === option.value
                                      ? "#e0eaff" // Highlight color
                                      : "transparent", // Default background
                                }}
                                onClick={() =>
                                  handleSelection(item.value, option.value)
                                }
                              >
                                {option.label}
                              </div>
                            ))
                          : EntityMaster[reportBy].dropdownOptions.map(
                              (option) => (
                                <div
                                  key={option}
                                  style={{
                                    ...styles.dropdownOption,
                                    backgroundColor:
                                      radioSelections[item.value] === option
                                        ? "#e0eaff" // Highlight color
                                        : "transparent", // Default background
                                  }}
                                  onClick={() =>
                                    handleSelection(item.value, option)
                                  }
                                >
                                  {option}
                                </div>
                              )
                            )}
                      </div>
                    )}
                  </div>
                ))}

                <div style={styles.radioGroup}>
                  {["Held", "Held Away", "Both"].map((type) => (
                    <label key={type} style={styles.radioLabel}>
                      <span> </span>
                      <input
                        className="me-1"
                        type="radio"
                        value={type}
                        checked={reportType === type}
                        onChange={() => handleReportTypeChange(type)}
                      />
                      {type}
                    </label>
                  ))}
                </div>

                <div style={styles.buttonGroup}>
                  <ButtonComp
                    wrapperName={"download_temp_wrapper"}
                    type="button"
                    btnStyle="box"
                    btnText={"Export"}
                    // disabled={file === null ? true : false}
                    btnIcon=""
                    onClick={() =>
                      handleExport(radioSelections.report, "export")
                    }
                  />
                  <ButtonComp
                    wrapperName={"download_temp_wrapper"}
                    type="button"
                    btnStyle="box"
                    btnText={"View"}
                    // disabled={file === null ? true : false}
                    btnIcon=""
                    // onClick={() => handleView()}
                    onClick={() => handleExport(radioSelections.report, "view")}
                  />
                </div>
              </div>
              {viewReportPDF !== null && (
                <LoadFromBase64pdf pdfdata={viewReportPDF} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

const styles = {
  container: {
    padding: "20px",
    borderRadius: "8px",
    width: "100%",
    margin: "auto",
  },
  radioGroup: {
    display: "flex",
    marginBottom: "20px",
  },
  radioGroup1: {
    display: "flex",
    marginBottom: "20px",
    padding: "20px",
  },
  radioLabel: {
    fontSize: "16px",
    fontWeight: "500",
    marginRight: "20px",
  },
  accordionContainer: {
    marginBottom: "10px",
    borderRadius: "20px",
    overflow: "hidden",
  },
  accordionHeader: {
    padding: "10px",
    backgroundColor: "#f0f4ff",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "500",
    color: "#38479B",
  },
  accordionContent: {
    padding: "10px",
    backgroundColor: "#FFFFFF",
    color: "#38479B",
  },
  buttonGroup: {
    display: "flex",
    gap: "5px",
    marginTop: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4f74f7",
    color: "white",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
  },
  dropdown: {
    padding: "8px",
    fontSize: "16px",
    marginBottom: "10px",
    border: "none",
  },
  dropdownOption: {
    padding: "10px",
    cursor: "pointer",
    borderRadius: "15px",
    transition: "background-color 0.3s ease", // Smooth transition effect
  },
  dropdownOptionHover: {
    backgroundColor: "#f9f9f9", // Optional hover effect
  },
};
