import {
  faEye,
  faEyeSlash,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../Component/ButtonComp/ButtonComp";
import SelectElement from "../../Component/ComponentsInput/InputSelect";
import InputText from "../../Component/ComponentsInput/InputText";
import "../../Component/ComponentsInput/InputText.scss";
import Inputcheckbox from "../../Component/ComponentsInput/Inputcheckbox";
import { ValidationPattern } from "../../ValidationPattern/ValidationPattern";
import { GetSvglogo } from "../../assets/img/app/GetSvglogo";
import { getTenantThemekey } from "../../util/Authenticate/TenantMasterconfig";
import PatternMessage from "../../util/PatternMessage";
import { GetSvgIcon } from "./../../assets/img/app/GetSvgIcon";

const FormComponent = ({ initialData, onSubmit, Showrecaptchabtn }) => {
  const { t } = useTranslation(["Common", "Messages", "Form"]);
  const [urlpage, setUrlpage] = useState();
  const [urlpageType, setUrlpageType] = useState();
  useEffect(() => {
    fetchUrl();
  }, [urlpage]);

  const fetchUrl = () => {
    let urlElements = window.location.href.split("/");
    let urlElelement = urlElements[3];
    let urlElelement5 = urlElements[4];
    if (urlElelement5 === "rm") {
      setUrlpageType(urlElelement5);
    }
    setUrlpage(`${urlElelement}`);
  };

  const {
    register,
    handleSubmit,

    formState: { errors },
    reset,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  } = useForm({ defaultValues: initialData });
  const useFromProps = {
    register,
    errors,
    setValue,
    trigger,
    control,
    watch,
    getValues,
  };

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showrePassword, setShowrePassword] = useState(false);
  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData]);

  const isViewurlpage = urlpage === "view";
  const username = watch("username");
  const password = watch("password");
  const rePassword = watch("rePassword");

  const isValid = ValidFunction();

  function ValidFunction() {
    let Valid;
    if (urlpage === "ForgotPassword") {
      Valid = username && password && rePassword;
    } else if (urlpage === "login") {
      Valid = username && password;
    } else if (urlpage === "signup") {
      Valid = username && password;
    } else {
      Valid = true;
    }
    return Valid;
  }

  function handleBtnClick(e) {
    navigate(`/${e}`);
  }
  const ButtonName =
    urlpage == "login" ? "Login" : urlpage == "signup" ? "Signup" : "Proceed";
  const LoginLogo =
    urlpageType == "rm"
      ? "RMLogin"
      : urlpage == "login"
      ? "ClientLoginVector"
      : urlpage == "signup"
      ? "Signup"
      : "ForgotPassword";
  // console.log("ForgotPassword", validatelogoFunction());
  return (
    <div className="row m-auto align-content-center w-100">
      <div className="col-lg-5 col-md-5 col-sm-12 col-12 ">
        <div class="card logincard m-auto">
          <div class="card-body">
            <div className="applogo">
              <GetSvglogo
                iconName={getTenantThemekey()}
                color={"d"}
              ></GetSvglogo>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {urlpageType == "rm" ? (
                <div>
                  <SelectElement
                    {...useFromProps}
                    useForm={useForm}
                    register={() => {}}
                    // setValue={()=> {}}
                    // errors={{}}
                    // divClassName={{}}
                    // control={{}}
                    isMulti={false}
                    readOnly={false}
                    previewFlag={""}
                    onSelect={() => {}}
                    handleInputChange={() => {}}
                    registerName={"LoginAs"}
                    mandatory={true}
                    labelName="Login as"
                    options={[
                      {
                        label: "Relationship Manager",
                        value: "relationship_manager",
                      },
                      {
                        label: "Operation Manager",
                        value: "operation_manager",
                      },
                      { label: "Admin", value: "super_admin" },
                    ]}
                  />
                </div>
              ) : null}

              <div
                className={
                  urlpage === "ForgotPassword" || urlpage === "forgotuser"
                    ? "d-none"
                    : ""
                }
              >
                <InputText
                  {...useFromProps}
                  useForm={useForm}
                  maxLength={50}
                  minLength={1}
                  readOnly={isViewurlpage}
                  disabled={isViewurlpage}
                  type="text"
                  labelName={"Username"}
                  pattern={{
                    value: ValidationPattern.email,
                    // message: t("Form:App_lms_Form_00001"),
                    message: PatternMessage(
                      "alphabet",
                      t("Form:App_lms_Form_00001")
                    ),
                  }}
                  registerName={"username"}
                  name={"username"}
                  mandatory={
                    urlpage === "ForgotPassword" || urlpage === "forgotuser"
                      ? false
                      : true
                  }
                  onPaste={false}
                  onCopy={false}
                  previewFlag={isViewurlpage}
                  onChange={() => {}}
                />
              </div>

              <div
                className={
                  urlpage == "ForgotPassword" ||
                  urlpage === "forgotuser" ||
                  urlpage === "signup"
                    ? ""
                    : "d-none"
                }
              >
                <h3 className={urlpage === "ForgotPassword" ? "" : "d-none"}>
                  Reset Your Password
                </h3>
                <p className={urlpage === "ForgotPassword" ? "" : "d-none"}>
                  Enter the email id you are register with
                </p>
                <InputText
                  {...useFromProps}
                  useForm={useForm}
                  readOnly={isViewurlpage}
                  disabled={isViewurlpage}
                  type="email"
                  labelName={"Email"}
                  pattern={{
                    value: ValidationPattern.email,
                    // message: t("Form:App_lms_Form_00001"),
                    message: PatternMessage("email", t("Messages:onlyEmail")),
                  }}
                  registerName={"email"}
                  name={"email"}
                  mandatory={
                    urlpage == "ForgotPassword" ||
                    urlpage === "forgotuser" ||
                    urlpage === "SignUp"
                      ? true
                      : false
                  }
                  onPaste={false}
                  onCopy={false}
                  previewFlag={isViewurlpage}
                  onChange={() => {}}
                />
              </div>

              <div
                className={
                  urlpage == "ForgotPassword" ||
                  urlpage == "login" ||
                  urlpage === "signup"
                    ? ""
                    : "d-none"
                }
              >
                <div className=" password-group">
                  <InputText
                    {...useFromProps}
                    useForm={useForm}
                    maxLength={20}
                    minLength={1}
                    readOnly={isViewurlpage}
                    disabled={isViewurlpage}
                    type={showPassword ? "text" : "password"}
                    labelName={"Password"}
                    pattern={{
                      value: ValidationPattern.password,
                      // message: t("Form:App_lms_Form_00001"),
                      message: PatternMessage(
                        "invalid",
                        t("Form:App_lms_Form_00090")
                      ),
                    }}
                    registerName={"password"}
                    name={"password"}
                    mandatory={
                      urlpage == "ForgotPassword" ||
                      urlpage == "login" ||
                      urlpage === "signup"
                        ? true
                        : false
                    }
                    onPaste={false}
                    onCopy={false}
                    previewFlag={isViewurlpage}
                    onChange={() => {}}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-space-between">
                {/* <img className="captchaImage" src={Captcha}></img> */}
                <InputText
                  {...useFromProps}
                  useForm={useForm}
                  maxLength={6}
                  minLength={1}
                  readOnly={true}
                  disabled={true}
                  type="text"
                  labelName={""}
                  registerName={"inputCaptcha"}
                  name={"inputCaptcha"}
                  onPaste={false}
                  onCopy={false}
                  previewFlag={true}
                  onChange={() => {}}
                />
                <div className="captchabtn">
                  {" "}
                  <FontAwesomeIcon
                    icon={faRotateLeft}
                    size="xl"
                    onClick={Showrecaptchabtn}
                  />
                </div>

                <div
                  className={
                    urlpage === "ForgotPassword" || urlpage === "forgotuser"
                      ? "d-none"
                      : ""
                  }
                >
                  <InputText
                    {...useFromProps}
                    useForm={useForm}
                    maxLength={6}
                    minLength={1}
                    readOnly={isViewurlpage}
                    disabled={isViewurlpage}
                    type="text"
                    labelName={"Captcha"}
                    pattern={{
                      // value: ValidationPattern.captha,
                      // message: t("Form:App_lms_Form_00001"),
                      message: PatternMessage(
                        "invalid",
                        t("Form:App_lms_Form_00089")
                      ),
                    }}
                    registerName={"Captcha"}
                    name={"captcha"}
                    mandatory={
                      urlpage === "ForgotPassword" || urlpage === "forgotuser"
                        ? false
                        : true
                    }
                    onPaste={false}
                    onCopy={false}
                    previewFlag={isViewurlpage}
                    onChange={() => {}}
                  />
                </div>
              </div>

              <div className={urlpage == "ForgotPassword" ? "" : "d-none"}>
                <div className=" password-group">
                  <InputText
                    {...useFromProps}
                    useForm={useForm}
                    maxLength={20}
                    minLength={1}
                    readOnly={isViewurlpage}
                    disabled={isViewurlpage}
                    type={showrePassword ? "text" : "password"}
                    labelName={"rePassword"}
                    pattern={{
                      value: ValidationPattern.alphabet,
                      // message: t("Form:App_lms_Form_00001"),
                      message: PatternMessage(
                        "alphabet",
                        t("Form:App_lms_Form_00001")
                      ),
                    }}
                    registerName={"repassword"}
                    name={"repassword"}
                    mandatory={urlpage == "ForgotPassword" ? true : false}
                    onPaste={false}
                    onCopy={false}
                    previewFlag={isViewurlpage}
                    onChange={() => {}}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={() => setShowrePassword(!showrePassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
              <div className={urlpage === "login" ? "" : "d-none"}>
                <div className="checkboxdiv">
                  <Inputcheckbox
                    {...useFromProps}
                    useForm={useForm}
                    registerName="checkbox"
                    type={"checkbox"}
                    labelName={
                      <>
                        I agree to the{" "}
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          Privacy Policy
                        </a>{" "}
                        of the website
                      </>
                    }
                    mandatory={true}
                    errorLabel={"Check Box"}
                  />
                </div>
              </div>

              {!isViewurlpage && (
                <ButtonComp
                  wrapperName="submit_btn_wrapper mt-5"
                  type="submit"
                  btnStyle="box"
                  btnText={ButtonName}
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                />
              )}
            </form>
          </div>
        </div>
        <div className="d-flex justify-content-around align-items-around">
          {urlpageType != "rm" &&
            urlpage != "signup" &&
            urlpage != "forgotuser" &&
            urlpage != "ForgotPassword" && (
              <span>
                Don't have an account? {""}
                <button
                  className="btn logbtn"
                  onClick={() => handleBtnClick("signup")}
                >
                  Sign Up
                </button>
              </span>
            )}
        </div>
        <div className="mt-3 d-flex justify-content-around align-items-around">
          {urlpageType != "rm" &&
            urlpage != "signup" &&
            urlpage != "forgotuser" &&
            urlpage != "ForgotPassword" && (
              <button
                className="btn logbtn"
                onClick={() => handleBtnClick("forgotuser")}
              >
                Forget User Id ?
              </button>
            )}
          {urlpageType != "rm" &&
            urlpage != "signup" &&
            urlpage != "forgotuser" &&
            urlpage != "ForgotPassword" && (
              <button
                className="btn logbtn"
                onClick={() => handleBtnClick("ForgotPassword")}
              >
                Forgot Password ?
              </button>
            )}
          {urlpage == "signup" && urlpage != "forgotuser" && (
            <span>
              Already have an account ? {""}
              <button
                className="btn logbtn"
                onClick={() => handleBtnClick("login")}
              >
                Login
              </button>
            </span>
          )}
        </div>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 col-12 d-flex justify-content-end">
        <div className="Illustration">
          <GetSvgIcon iconName={LoginLogo} color={"d"}></GetSvgIcon>
        </div>
      </div>
    </div>
  );
};

FormComponent.propTypes = {
  initialData: PropTypes.any,
  onSubmit: PropTypes.func,
  handleBtnClick: PropTypes.func,
};
export default FormComponent;
function cssFunction(previewFlag, errors, registerName) {
  if (previewFlag) {
    return "app_input previewStyle";
  } else if (errors?.[registerName]) {
    return "app_input errorsborder";
  } else {
    return "app_input";
  }
}
