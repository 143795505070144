import {
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faRetweet
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconSetQuartzLight, themeQuartz } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
// to use myTheme in an application, pass it to the theme grid option
// to use myTheme in an application, pass it to the theme grid option
const myTheme = themeQuartz
	.withPart(iconSetQuartzLight)
	.withParams({
        accentColor: "#304D76DB",
        backgroundColor: "#ffffff",
        borderColor: "#00000029",
        borderRadius: 1,
        browserColorScheme: "inherit",
        columnBorder: false,
        fontFamily: {
            googleFont: "Roboto"
        },
        foregroundColor: "#000000",
        headerBackgroundColor: "#FFFFFF",
        headerFontFamily: {
            googleFont: "Roboto"
        },
        headerFontSize: 14,
        headerFontWeight: 500,
        headerTextColor: "#000000",
        oddRowBackgroundColor: "#BFBFBF26",
        rowBorder: true,
        sidePanelBorder: false,
        spacing: 10,
        wrapperBorder: false,
        wrapperBorderRadius: 0
    });

export default function AgTable({
  columnKeys,
  columnDefs,
  rowData,
  filenames,
  onSelectionChanged,
  StyleClass,
  type,
  showTotalRow = false,
}) {
  const gridRef = useRef();
  const [searchText, setSearchText] = useState("");

  // Default column settings
  const defaultColDef = useMemo(
    () => ({
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      resizable: true,
      filter: true,
      minWidth: 100,
      suppressSizeToFit: true,
      flex: 1,
      tooltipComponent: "customTooltip",
      cellStyle: { textAlign: "center" }
    }),
    []
  );

  const rowClassRules = {
    "even-row": (params) => params.node.rowIndex % 2 === 0,
    "odd-row": (params) => params.node.rowIndex % 2 !== 0,
  };

  const onGridReady = useCallback((params) => {
    gridRef.current = params.api; // Set the gridRef for accessing API directly
  }, []);

  const onQuickFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchText(value);
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.setFilterModel({
          securityName: {
            filterType: "text",
            type: "contains",
            filter: event.target.value,
          },
          weight: {
            filterType: "text",
            type: "contains",
            filter: event.target.value,
          },
        });
        gridRef.current.api.onFilterChanged();
      }

      // Generate filterColumns dynamically from rowData
      const filterColumns = columnDefs.map((col) => col.field).filter(Boolean);

      // Create a filter model to apply the search text to each column
      const filterModel = {};
      filterColumns.forEach((col) => {
        filterModel[col] = {
          filterType: "text",
          type: "contains",
          filter: value,
        };
      });

      // Apply the filter model to the grid
      if (gridRef.current) {
        gridRef.current.setFilterModel(filterModel);
      }
    },
    [columnDefs]
  );

  const clearFilters = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.setFilterModel(null);
    }
  }, []);

  const date = new Date();
  const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, "_");
  const filenamestem = filenames
    ? `${filenames}_${formattedDate}.csv`
    : `Download_${formattedDate}.csv`;

  const onBtnExport = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.exportDataAsCsv({
        skipHeader: false,
        skipFooters: true,
        skipRowGroups: true,
        fileName: filenamestem,
        columnKeys,
      });
    }
  }, [filenamestem, columnKeys]);

  // Calculate total row data if required
  const totalRowData = useMemo(() => {
    if (!showTotalRow) return null; // Skip calculation if the total row is not required

    const totals = columnDefs.reduce((acc, col) => {
      if (col.field && typeof rowData[0]?.[col.field] === "number") {
        acc[col.field] = rowData.reduce(
          (sum, item) => sum + (item[col.field] || 0),
          0
        );
      }
      return acc;
    }, {});

    totals.Product = "Total"; // Add a label to the total row
    return [totals];
  }, [rowData, columnDefs, showTotalRow]);

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.sizeColumnsToFit();
    }
  }, []);

  return (
    <>
      <Row className="mb-3 ">
        <div className="col-md-4 col-12"></div>
        <div className="col-md-8 col-12 d-flex justify-content-end align-items-end">
          <div className="d-flex table-actions-container">
            <div className="table-sub-actions">
              <div className="export">
                <button className={StyleClass} onClick={onBtnExport}>
                  {type === "dashboard" ? (
                    <>
                      <FontAwesomeIcon icon={faFileExcel} /> Summary Excel
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faFileCsv} />
                  )}
                </button>
              </div>
              <div className="reset mx-3 me-2">
                <button
                  className={StyleClass}
                  onClick={type === "dashboard" ? onBtnExport : clearFilters}
                >
                  {type === "dashboard" ? (
                    <>
                      <FontAwesomeIcon icon={faFilePdf} /> Summary
                    </>
                  ) : (
                    <FontAwesomeIcon icon={faRetweet} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <Col>
          <div className="ag-grid-div">
            <AgGridReact
              theme={myTheme}
              className="me-2 AgGridTable"
              columnDefs={columnDefs}
              rowData={rowData}
              paginationPageSize={10}
              domLayout="autoHeight" // Ensure grid respects the container's scrollable area
              ref={gridRef}
              tooltipShowDelay={5}
              defaultColDef={{
                ...defaultColDef,
                cellStyle: { textAlign: "center" },
              }}
              rowGroupPanelShow={"always"}
              pivotPanelShow={"always"}
              onGridReady={onGridReady}
              pagination={true}
              onFirstDataRendered={onFirstDataRendered}
              onSelectionChanged={onSelectionChanged}
              rowClassRules={rowClassRules}
              pinnedBottomRowData={showTotalRow ? totalRowData : undefined}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

AgTable.propTypes = {
  columnKeys: PropTypes.any,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.object),
  filenames: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  StyleClass: PropTypes.string,
  type: PropTypes.string,
  showTotalRow: PropTypes.bool,
};
