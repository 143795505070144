import React, { useState } from "react";
import GaugeChart from "react-gauge-chart";
import '../RiskProfiling/RiskGuage.scss'; // import CSS file
import ButtonComp from "../../Component/ButtonComp/ButtonComp";

const RiskGauge = () => {
  const [riskLevel, setRiskLevel] = useState(0.7); // Default risk level

  // Define the risk levels and corresponding percentages
  const riskLevels = [
    { label: "R1", value: 0.1 }, // 10% for R1
    { label: "R2", value: 0.3 },
    { label: "R3", value: 0.5 },
    { label: "R4", value: 0.7 },
    { label: "R5", value: 0.9 }, // 90% for R5
  ];

  const getRiskLabel = () => {
    return riskLevels.find((level) => level.value === riskLevel)?.label || "R4";
  };

  return (
    <div className="risk-gauge-container">
      <h2>RISK</h2>

      {/* Gauge Chart */}
      <GaugeChart
        id="risk-gauge"
        nrOfLevels={5}
        arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
        colors={["#3AB54A", "#8BC34A", "#FFEB3B", "#FF9800", "#D32F2F"]}
        percent={riskLevel}
        arcPadding={0.02}
        textColor={"#000"}
        needleColor={"#000"}
        needleBaseColor={"#000"}
      />

      {/* Risk Profile Text */}
      <p className="risk-profile-text">
        Based on the information you have provided, your risk profile has been
        evaluated as <b>{getRiskLabel()}</b>
      </p>

      <div className="buttonDiv mt-2">
        <div className="me-4">
          <ButtonComp
            wrapperName="btn_wrapper"
            type="button"
            btnStyle="box"
            btnText={"Invest Now"}
            onClick={() => alert("Invest Now Clicked")}
          />
        </div>
        <div className="">
          <ButtonComp
            wrapperName="btn_wrapper"
            type="button"
            btnStyle="box"
            btnText={"RO Access Your Risk Profile"}
            onClick={() => alert("Access Risk Profile")}
          />
        </div>
      </div>

      {/* Dropdown to Change Risk Level */}
      <div className="risk-dropdown">
        <label htmlFor="riskSelect">Select Risk Level: </label>
        <select
          id="riskSelect"
          value={riskLevel}
          onChange={(e) => setRiskLevel(parseFloat(e.target.value))}
        >
          {riskLevels.map((level) => (
            <option key={level.label} value={level.value}>
              {level.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default RiskGauge;
