import { useEffect, useState } from "react";
import { encrypt } from "./CryptoJS";
import { filtercurrentRole } from "./currentRealmRole";
import { getLocalStorage, getToken, validateJwt } from "./index";
import LogoutButton from "./logout";

const AuthComponent = () => {
  const [isIdlehandleLogoutOpen, setIsIdlehandleLogoutOpen] = useState(false);

  const [uservalidity, setuservalidity] = useState();
  /**
   * Checks the validity of the current token and triggers auto logout if invalid.
   */
  const checkTokenValidity = () => {
    const token = getToken();

    if (token) {
      if (!validateJwt(token)) {
        setuservalidity("system");
        handleAutoLogout();
      } else {
        let currentRole = filtercurrentRole();

        if (currentRole.length > 0) {
        } else {
          setuservalidity("Bad_credentials");
          handleAutoLogout();
        }
      }
    } else {
      setuservalidity("system");
      handleAutoLogout();
    }
  };

  /**
   * Handles user auto-logout by clearing data and redirecting to the login page.
   */
  const handleAutoLogout = () => {
    setIsIdlehandleLogoutOpen(true);
  };

  /**
   * Sets up an interval to periodically check token validity.
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkTokenValidity();
    }, 3000); // Default: 3000 ms (3 seconds)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Component renders nothing isIdlehandleLogoutOpenT2 ? "Bad_credentials" :
  return (
    <>
      {(uservalidity === "system" || uservalidity === "Bad_credentials") && (
        <>
          {isIdlehandleLogoutOpen ? (
            <LogoutButton
              logoutType={getLocalStorage(encrypt("Login_Type"))}
              logoutAction={uservalidity}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default AuthComponent;
