import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./RangeInputField.scss";
import { negativeFormatter } from "../../util/Authenticate";
 
const RangeInputField = ({ data }) => {
  return (
    <div className="range-input-container">
      {data.map((item, index) => {
        const { absoluteValue, icon, iconClass } = negativeFormatter(item?.value);
 
        return (
          <div key={index} className="range-input-item">
            <div className="label-row">
              <span className="label">{item?.label}</span>
              <span className={`value ${iconClass}`}>
                {absoluteValue}
                {item?.icon && (
                  <span className="icon">
                    <FontAwesomeIcon icon={icon} />
                  </span>
                )}
              </span>
            </div>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width: `${(item.value / item.max) * 100}%`,
                  backgroundColor: item.color,
                }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
 
export default RangeInputField;
 
 