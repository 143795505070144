import {
  faCaretUp,
  faFileExcel,
  faFilePdf,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import NoData3 from "../../../assets/img/NoData3.png";
import ApppieChart from "../../../Component/ComponentsChart/ApppieChart";
import RangeInputField from "../../../Component/ComponentsChart/RangeInputField";
import TreemapChart from "../../../Component/ComponentsChart/TreemapChart";
import { getData } from "../../../Component/ComponentsTable/agData";
import AgTable from "../../../Component/ComponentsTable/AgTable";
import { AmountFormatter } from "../../../Component/ComponentsTable/AmountFormatter";
import { AssetAllocation } from "../../../Component/ComponentsTable/AssetAllocation";
import CustomCard from "../../../Component/ComponentsTable/CustomCard";
import LastTransaction from "../../../Component/ComponentsTable/LastTransaction";
import SystematicTransactions from "../../../Component/ComponentsTable/SystematicTransactions";
import Pagehader from "../../../Layout/Pagehader";
import { Apiurl } from "../../../util/apiurl";
import axiosInstance from "../../../util/axiosInstance";
import Loader from "../../../util/Loader";
import NewsSlider from "../NewsSlider";
import "./ClientDashboard.scss";
import NewsSliderClient from "../NewsSliderClient";

export default function ClientDashboard() {
  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState("");
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(Apiurl.userDetails);
      const userData = response.data;
      fetchInvestmentChartData(userData.clientId);
      fetchAllHolding(userData.clientId);
      fetchAllAssetAllocation(userData.clientId);
      fetchTopHoldings(userData.clientId);
      fetchProductAllocation(userData.clientId);
      fetchSecurityExposure(userData.clientId);
      fetchAssetAllocationerformanceChart(userData.clientId);
      fetchSectorExposure(userData.clientId);
      fetchAMC(userData.clientId);
      fetchMarketCap(userData.clientId);
      setFullname(userData.fullName);
      fetchAllHoldingReportsList(userData.clientId);
      fetchPortfolioXirr(userData.clientId);
    } catch (error) {
      console.error("Login error: ", error);
      toast.error(t("login Failed"));
    } finally {
      setLoading(false);
    }
  };

  const [selectedOption, setSelectedOption] = useState("mutual_funds");
  const [selectedOptioncard, setSelectedOptioncard] = useState("Mutual Funds");
  const [investmentChartData, setInvestmentChartData] = useState("");
  const [allHoldingData, setAllHoldingData] = useState("");
  const [assetData, setAssetData] = useState([]);
  const [assetMapData, setAssetMapData] = useState([]);
  const [rowData2, setRowData2] = useState([]);
  const [rowData3, setRowData3] = useState([]);
  const [rowData4, setRowData4] = useState([]);
  const [securityName, setSecurityName] = useState("");
  const [securityExposureData, setSecurityExposureData] = useState([]);
  const [mutualFundData, setMutualFundData] = useState([]);
  const [stocksData, setStocksData] = useState([]);
  const [topHoldingData, setTopHoldingsData] = useState([]);
  const [assetPerformance, setAssetPerformance] = useState([]);
  const [assetPerformanceLabel, setAssetPerformanceLabel] = useState([]);
  const [sectorExposure, setSectorExposure] = useState([]);
  const [amc, setAMC] = useState([]);
  const [marketCap, setMarketCap] = useState([]);
  const [mutualFundOptions, setMutualFundOption] = useState([]);
  const [stockOptions, setStocksOption] = useState([]);
  const [holdingProductList, setHoldingProductList] = useState([]);
  const [portfolioLabel, setPortfolioLabel] = useState([]);
  const [portfolioValues, setPortfolioValues] = useState([]);


  const customColors = ["#ff6f61", "#ffa500", "#8bc34a", "#00bcd4", "#673ab7"];
  const AssetAllocationTreeMapColors = [
    "#B570B0",
    "#E38DDD",
    "#ECB3E8",
    "#F1C6EE",
    "#F5D9F3",
    "#FAECF9",
    "#FFBEFA",
  ];
  const securityExposureColors = [
    "#7887DF",
    "#4D5EC9",
    "#5265DE",
    "#5E86FF",
    "#6076FA",
    "#626FBA",
    "#6376ED",
    "#7B86C9",
    "#8495FF",
    "#959AB6",
    "#A0ADFF",
  ];
  const mutualFundAMCExposureColors = [
    "#219540",
    "#82B378",
    "#88E482",
    "#92C98E",
    "#AAEB80",
    "#ADD1AA",
    "#B5EBB1",
    "#BADBA5",
    "#CEF1B8",
    "#D7FFD4",
    "#E1FFE9",
  ];
  const sectorExposureColors = [
    "#E38DDD",
    "#ECB3E8",
    "#EF9B62",
    "#F5D9F3",
    "#FFA34F",
    "#FFBD94",
    "#FFBEFA",
  ];
  const marketCapExposureColors = [
    "#BC958E",
    "#BD523F",
    "#D29A8F",
    "#DDCAC7",
    "#DF8C7D",
    "#E1BAB3",
    "#E7BDB6",
    "#EA705A",
    "#F36F56",
    "#FEBDB1",
    "#FF8973",
    "#FF9986",
    "#FFBBAE",
    "#FFD1C9",
  ];

  const fetchInvestmentChartData = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.investmentChartData}${id}`
      );
      const result = response.data;
      setInvestmentChartData(result);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllHolding = async (id) => {
    try {
      const response = await axiosInstance.get(`${Apiurl.combineHolding}${id}`);
      const result = response.data;
      Object.values(result).map((item) => {});
      const stockData = result.filter((item) => item.productName === "Stocks");
      const mutualFundsData = result.filter(
        (item) => item.productName === "Mutual Funds"
      );
      // console.log("sss", stockData);
      Object.values(stockData).map((item) => {
        setStocksOption(item.productName);
        setStocksData([
          { label: "Current Value", value: item.currentValue },
          { label: "Invested Value", value: item.investedValue },
          { label: "IRR", value: item.irr },
          { label: "Gain/Loss", value: item.lossGain },
          { label: "STCG", value: item.stcg },
          { label: "LTCG", value: item.ltcg },
        ]);
      });
      Object.values(mutualFundsData).map((item) => {
        setMutualFundOption(item.productName);
        setMutualFundData([
          { label: "Current Value", value: item.currentValue },
          { label: "Invested Value", value: item.investedValue },
          { label: "IRR", value: item.irr },
          { label: "Gain/Loss", value: item.lossGain },
          { label: "STCG", value: item.stcg },
          { label: "LTCG", value: item.ltcg },
        ]);
      });
      // Set the state with the filtered arrays
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };
  // console.log("sss", stocksData);

  const fetchAllHoldingReportsList = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.holdingsProductList}${id}`
      );
      const result = response.data;
      Object.values(result).map((item) => {
        let SingleData = {
          label: item.productName,
          value: item.instrumentNames[0],
        };
        setHoldingProductList((prevData) => [...prevData, SingleData]);
      });

      // Set the state with the filtered arrays
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPortfolioXirr = async (id) => {
    let temp_label = [], temp_value = []
    try {
      const response = await axiosInstance.get(
        `${Apiurl.portfolioXirr}${id}`
      );
      const result = response.data;
      // const keys = Object.keys(result[0]);
      // const values = result.map(item => Object.values(item)).flat();
      // temp_label.push(keys)
      // temp_value.push(values)
      // setPortfolioLabel(temp_label)
      
        
      
      // setPortfolioValues(
      //   {
      //     label: "AUM",
      //     data: temp_value,
      //     backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
      //     borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
      //     borderWidth: 1,
      //   }
      // )
      const categories = Object.keys(result[0]);
      setPortfolioLabel(categories); // Store categories in state

      const temp_marketData = result.map(item => Object.values(item)).flat();
      let SingleData = {
        label: categories,
        data: temp_marketData,
        backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
        borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
        borderWidth: 1,
      };
      temp_value.push(SingleData);
      setPortfolioValues(temp_value);



      // Set the state with the filtered arrays
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };


  const fetchAllAssetAllocation = async (id) => {
    let temp = [];
    try {
      const response = await axiosInstance.get(
        `${Apiurl.allAssetAllocation}${id}`
      );
      const result = response.data;
      setAssetData([]);
      setAssetMapData([]);
      Object.values(result).map((item) => {
        let SingleData = {
          type: item.description,
          value: item.value,
        };
        let data1 = [
          {
            x: item.description,
            y: item.percentageValue,
          },
        ];

        temp.push({
          name: item.description,
          data: data1,
        });
        setAssetData((prevData) => [...prevData, SingleData]);
      });

      setAssetMapData(temp);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTopHoldings = async (id) => {
    try {
      const response = await axiosInstance.get(`${Apiurl.topHoldings}${id}`);
      const result = response.data;
      setRowData2(result);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProductAllocation = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${Apiurl.productAllocation}${id}`
      );
      const result = response.data;
      setRowData3(result);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSecurityExposure = async (id) => {
    let temp = [];
    try {
      const response = await axiosInstance.get(
        `${Apiurl.securityExposure}${id}`
      );
      const result = response.data;
      setRowData4(result);
      Object.values(result).map((item) => {
        let data1 = [
          {
            x: item.type,
            y: item.weight,
          },
        ];

        temp.push({
          name: item.securityName,
          data: data1,
        });
      });
      setSecurityExposureData(temp);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssetAllocationerformanceChart = async (id) => {
    const temp_category_val = [];
    try {
      const response = await axiosInstance.get(
        `${Apiurl.assetAllocationChart}${id}`
      );
      const result = response.data;
      const categories = result.map((item) => item.description);
      setAssetPerformanceLabel(categories); // Store categories in state

      const temp_marketData = result.map((item) => item.xirr);
      let SingleData = {
        label: "AUM",
        data: temp_marketData,
        backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
        borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
        borderWidth: 1,
      };
      temp_category_val.push(SingleData);
      setAssetPerformance(temp_category_val);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSectorExposure = async (id) => {
    let temp = [];
    try {
      const response = await axiosInstance.get(`${Apiurl.sectorExposure}${id}`);
      const result = response.data;
      Object.values(result).map((item) => {
        let data1 = [
          {
            x: item.sector,
            // y: item.percentageValue,
            y: 2.0,
          },
        ];

        temp.push({
          name: item.sector,
          data: data1,
        });
      });
      setSectorExposure(temp);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAMC = async (id) => {
    let temp = [];
    try {
      const response = await axiosInstance.get(
        `${Apiurl.mutualFundAMCExposure}${id}`
      );
      const result = response.data;
      Object.values(result).map((item) => {
        let data1 = [
          {
            x: item.name,
            y: item.percentage,
          },
        ];

        temp.push({
          name: item.name,
          data: data1,
        });
      });
      setAMC(temp);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMarketCap = async (id) => {
    let temp = [];
    try {
      const response = await axiosInstance.get(`${Apiurl.marketCap}${id}`);
      const result = response.data;
      Object.values(result).map((item) => {
        if (item.name != null && item.percentage != null) {
          let data1 = [
            {
              x: item.name,
              y: item.percentage,
            },
          ];

          temp.push({
            name: item.name,
            data: data1,
          });
        }
      });
      setMarketCap(temp);
    } catch (error) {
      console.error("Login error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (event, cardname) => {
    setSelectedOption(event);
    setSelectedOptioncard(cardname);
  };
  const [optionbtn, setOptionbtn] = useState("My");
  const handleOptionbtnGro = (event) => {
    setOptionbtn(event);
  };

  const transactionData = {
    activeSIPs: 61,
    primaryFund: "Nippon India retirement fund wealth creation (G)",
    additionalFunds: 60,
    totalAmount: 497500,
  };

  const [rowData, setRowData] = useState(getData());
  const columnDefs = [
    {
      headerName: "Mutual Fund Name",
      field: "firstName",
      sortable: true,
      filter: true,
      minWidth: 180,
    },
    {
      headerName: "Current Value",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),

      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Invested Value",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "IRR",
      field: "lastName",
      cellRenderer: (params) => AmountFormatterFunction(params.data.age),
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Gain/Loss",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "STCG",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "LTCG",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
    {
      headerName: "Weight(%)",
      field: "lastName",
      sortable: true,
      filter: true,
      maxWidth: 180,
    },
  ];

  const rowData1 = [
    { securityName: "ICICI Bank Ltd", weight: "5.47%" },
    { securityName: "HDFC Bank Ltd", weight: "6.75%" },
    { securityName: "SBI", weight: "4.25%" },
    { securityName: "Axis", weight: "8.65%" },
    { securityName: "PNB", weight: "12.85%" },
    // Add more dummy rows as needed
  ];
  const columnDefs1 = [
    {
      headerName: "Security Name",
      field: "securityName",
      sortable: true,
      filter: true,
      minWidth: 180,
      suppressMovable: true,
    },
    {
      headerName: "Weight %",
      field: "weight",
      // cellRenderer: (params) => AmountFormatterFunction(params.data.age),
      sortable: true,
      filter: true,
      maxWidth: 180,
      suppressMovable: true,
    },
  ];

  const columnDefs2 = [
    {
      headerName: "Security Name",
      field: "securityName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Current Value",
      field: "currentValue",
      sortable: true,
      filter: true,
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(params.value),
    },
    {
      headerName: "% of Portfolio",
      field: "percentageOfPortfolio",
      sortable: true,
      filter: true,
      valueFormatter: (params) => `${params.value}%`,
    },
  ];

  // const rowData2 = [
  //   {
  //     securityName: "Franklin India ST Income Rel G",
  //     currentValue: 13902600,
  //     portfolioPercentage: 11.71,
  //   },
  //   {
  //     securityName: "Kotak Bond Short Term Plan - Growth",
  //     currentValue: 13566351,
  //     portfolioPercentage: 11.43,
  //   },
  //   {
  //     securityName: "DHFL Pharmaerica Credit Opportunities Reg G",
  //     currentValue: 11466696,
  //     portfolioPercentage: 8.66,
  //   },
  //   {
  //     securityName: "Birla SL Frontline Equity",
  //     currentValue: 10025343,
  //     portfolioPercentage: 8.44,
  //   },
  //   {
  //     securityName: "ICICI Prudential Income Fund - Growth",
  //     currentValue: 8947582,
  //     portfolioPercentage: 5.85,
  //   },
  // ];

  const columnDefs3 = [
    { headerName: "Product", field: "product", sortable: true, filter: true },
    {
      headerName: "Current Value",
      field: "currentValue",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-US").format(params.data.currentValue), // Format numbers
    },
    {
      headerName: "%",
      field: "percentageValue",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value !== undefined ? `${params.data.percentageValue}%` : "", // Format as a percentage
    },
  ];

  const columnDefs4 = [
    {
      headerName: "Security Name",
      field: "securityName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      // filter: "agNumberColumnFilter",
    },
    {
      headerName: "%Wts",
      field: "weight",
      sortable: true,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value !== undefined ? `${params.data.weight}%` : "", // Format as a percentage
    },
  ];

  // const rowData3 = [
  //   { product: "Direct Equity", currentValue: 25961, percentage: 0.02 },
  //   {
  //     product: "Equity Mutual Fund",
  //     currentValue: 36580014,
  //     percentage: 30.82,
  //   },
  //   { product: "Alternate Asset", currentValue: 2868966, percentage: 2.24 },
  //   { product: "Debt Mutual Fund", currentValue: 77852752, percentage: 66.57 },
  //   {
  //     product: "Cash & Cash Equivalent",
  //     currentValue: 1599240,
  //     percentage: 1.35,
  //   },
  // ];

  const totalRowData = [
    {
      product: "Total",
      currentValue: rowData.reduce((sum, item) => sum + item.currentValue, 0),
      percentage: 100,
    },
  ];

  const getRowStyle = (params) => {
    return {
      backgroundColor: params.node.rowIndex % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternating colors
    };
  };

  // const assetData = [
  //   { type: "Debt", value: 77652752 },
  //   { type: "Equity", value: 36614967 },
  //   { type: "Alternate", value: 2656956 },
  //   { type: "Cash", value: 1599240 },
  // ];

  // const mutualFundOptions = ["Mutual Fund A", "Mutual Fund B", "Mutual Fund C"];
  // const [selectedFund, setSelectedFund] = useState("Mutual Fund A");

  // const stockOptions = ["Stock A", "Stock B", "Stock C"];
  // const [selectedStock, setSelectedStock] = useState("Stock A");

  // const mutualFundData = [
  //   { label: "Current Value", value: "17.89L" },
  //   { label: "Invested Value", value: "17.89L" },
  //   { label: "IRR", value: "17.89L" },
  //   { label: "Gain/Loss", value: "5.54" },
  //   { label: "STCG", value: "17.89L" },
  //   { label: "LTCG", value: "17.89L" },
  // ];

  const breadcrumbItems = [{ label: "Dashboard" }];
  const aumData = ["10.0", "20.99"];
  const aumDatalabels = ["Held Away", "Held"];
  const aumDatabackgroundColor = ["#8A60FA", "#38479B"];
  //
  const Marketdata = [
    {
      label: "AUM",
      data: [12, 19, 3, 50],
      backgroundColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
      borderColor: ["#5FB6FA", "#FA60A9", "#CA60FA", "#8A60FA"],
      borderWidth: 1,
    },
  ];
  const MarketDatalabels = ["Commodities", "Hybrid", "Equity", "Debt"];
  // AUMClientWisedata data
  const AUMClientWisedata = [
    {
      label: "AUM",
      data: [65, -59, 80],
      backgroundColor: "#6076FA",
      borderColor: "#6076FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Clients",
      data: [-5, 59, 80],
      backgroundColor: "#000",
      borderColor: "#000",
      borderWidth: 1,
      type: "line", // Type set to 'bar'
    },
  ];
  const AUMClientWiseDatalabels = ["<10", "10L- 1Cr", ">1Cr"];
  const AUMChartAxis = "x";

  // SIP

  const SIPdata = [
    {
      label: "AUM",
      data: [65, -59, 80, 65],
      backgroundColor: "#88E482",
      borderColor: "#88E482",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Clients",
      data: [-5, 59, 100, 50],
      backgroundColor: "#000",
      borderColor: "#000",
      borderWidth: 1,
      type: "line", // Type set to 'bar'
    },
  ];
  const SIPDatalabels = ["Monthly", "Weekly"];
  const SIPChartAxis = "x";
  // Transactions

  const Transactionsdata = [
    {
      label: "Net",
      data: [0, 59, 100, 50],
      backgroundColor: "#5FB6FA",
      borderColor: "#5FB6FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Purchase",
      data: [0, 59, 100, 50],
      backgroundColor: "#5FF6FA",
      borderColor: "#5FF6FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
    {
      label: "Redemption",
      data: [0, 59, 0, 50],
      backgroundColor: "#6076FA",
      borderColor: "#6076FA",
      borderWidth: 1,
      type: "bar", // Type set to 'bar'
    },
  ];
  const TransactionsDatalabels = ["Commodation", "Debt", "Equity", "Hybrid"];
  const TransactionsChartAxis = "y";

  //  Range input
  const rangeData = [
    {
      label: "Unrealised Gain/Loss",
      value: investmentChartData.unrealisedGainLoss,
      color: "#FFC107", // Yellow
      max: 10,
      icon: <FontAwesomeIcon icon={faCaretUp} color="green" />,
    },
    {
      label: "Absolute Return",
      value: investmentChartData.absoluteReturn,
      color: "#8B5CF6", // Purple
      max: 200,
    },
    {
      label: "Annualised Return",
      value: investmentChartData.annualisedReturn,
      color: "#FB7185", // Pink
      max: 100,
    },
  ];

  const TreeMapChartData = [
    // {
    //   name: "Category 1",
    //   data: [
    //     { x: "Item 1.1", y: 10 },
    //     { x: "Item 1.2", y: 20 },
    //     { x: "Item 1.3", y: 30 },
    //   ],
    // },
    // {
    //   name: "Category 2",
    //   data: [
    //     { x: "Item 2.1", y: 15 },
    //     { x: "Item 2.2", y: 25 },
    //     { x: "Item 2.3", y: 35 },
    //   ],
    // },
    // {
    //   name: "Category 3",
    //   data: [
    //     { x: "Item 3.1", y: 5 },
    //     { x: "Item 3.2", y: 10 },
    //     { x: "Item 3.3", y: 15 },
    //   ],
    // },
    // const seriesData = [
    {
      // name: "Asset Allocation",
      // data: [
      //   { x: "Very Long Label for Equity Allocation", y: 45 },
      //   { x: "Debt Allocation with Long Label", y: 30 },
      //   { x: "Real Estate Investments", y: 25 },
      //   { x: "Gold and Precious Metals", y: 15 },
      //   { x: "Cash Reserves and Liquidity", y: 10 },
      // ],
      name: "Asset Allocation",
      data: [
        { x: "Equity", y: 45 },
        { x: "Debt", y: 30 },
        { x: "Real Estate", y: 25 },
        { x: "Gold", y: 15 },
        { x: "Cash", y: 10 },
      ],
    },
    // ];
  ];

  const showTable = (val) => {
  console.log("fff", val);

  }

  return (
    <>
      {loading ? (
        <Loader pagename="Dashboard " />
      ) : (
        <>
          <Pagehader
            pagename="Dashboard"
            getfullName={fullname}
            Breadcrumbshow={false}
            breadcrumbItems={breadcrumbItems}
          ></Pagehader>
          <div className="pagebody">
            <div className="row">
              {/* Main Tile Section */}
              <div className="col-12 col-lg-8 d-flex flex-column dashboard_tile">
                {/* Total and Invest Values with Chart */}
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="h-100">
                      <div className="card-body p-3">
                        <div className="py-2 d-flex justify-content-between align-items-center">
                          <div className="text-center">
                            <h6 className="totalcardtext mb-1">Total Value</h6>{" "}
                            <h5 className="text-primary m-0">
                              <FontAwesomeIcon icon={faUserGroup} />{" "}
                              {investmentChartData.totalValue}
                            </h5>
                          </div>
                          <div className="text-center">
                            <h6 className="totalcardtext mb-1">Invest Value</h6>{" "}
                            <h5 className="text-success m-0">
                              <FontAwesomeIcon icon={faUser} />{" "}
                              {investmentChartData.investedvalue}
                            </h5>
                          </div>
                        </div>
                        <div className="aumcharts">
                          <ApppieChart
                            type="DoughnutChart"
                            legendposition="bottom"
                            legenddisplay={true}
                            Chartdata={[
                              {
                                label: "Dataset 1",
                                data: [30, 70],
                                backgroundColor: ["#8A60FA", "#38479B"],
                              },
                            ]}
                            Chartlabels={["External 0.0", "Held 0.0"]}
                            isSemiDoughnut={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Range Input Section */}
                  <div className="col-12 col-md-6 mt-3 mt-md-0">
                    <div className="h-100">
                      <div className="mt-5 card-body d-flex flex-column justify-content-center">
                        <div
                          className="p-3 mt-2"
                          style={{ borderLeft: "1px solid #ddd" }}
                        >
                          <RangeInputField data={rangeData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* News and Carousel Section */}
              <div className="col-12 col-lg-4">
                {/* First News/Carousel Card */}
                <div className="card newscards">
                  <div className="card-body p-0">
                    <NewsSliderClient addcss={"newscards"} />
                  </div>
                </div>
                {/* Second News/Carousel Card */}
                <div className="mt-3 card newscards">
                  <div className="card-body p-0">
                    <NewsSliderClient addcss={"newscards"} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className=" mt-5 holdingsDiv">
                <div className="">
                  <h3 style={{ display: "inline" }}>Holdings </h3>
                  <p
                    style={{
                      display: "inline",
                      fontStyle: "italic",
                    }}
                  >
                    (All values in INR)
                  </p>
                </div>
                <div className="buttonDiv">
                  <button className="buttonClass">
                    <FontAwesomeIcon icon={faFileExcel} /> Summary Excel
                  </button>
                  <button className="buttonClass">
                    <FontAwesomeIcon icon={faFilePdf} /> Summary
                  </button>
                </div>
              </div>
              <div className=" mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12">
                <CustomCard
                  title={mutualFundOptions}
                  data={mutualFundData}
                  weight={100}
                  mutualFundOptions={mutualFundOptions}
                  // onSelectFund={setSelectedFund}
                  handleOpenModal = {() => {showTable(value)}}
                  dropdownOptions={holdingProductList}
                />
              </div>
              {/* {After demo fix} */}
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12 mt-2">
                <CustomCard
                  title={stockOptions}
                  data={stocksData}
                  weight={60}
                  mutualFundOptions={stockOptions}
                  // onSelectFund={setSelectedStock}
                />
              </div>
            </div>

            {/* Charts Section */}
            <div className="row mt-5">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Asset Allocation</p>
                    <div className="dashbordchart mixbar">
                      <AssetAllocation data={assetData} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Asset Allocation</p>
                    <div className="dashbordchart">
                      <TreemapChart
                        data={assetMapData}
                        colors={AssetAllocationTreeMapColors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xl-12">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Top Holdings</p>
                    <div className="dashbordchart mixbar">
                      <AgTable
                        columnDefs={columnDefs2}
                        rowData={rowData2}
                        filenames="TopHoldings"
                        StyleClass="btn btn-primary"
                        type="table"
                        gridOptions={{
                          getRowStyle,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Product Allocation</p>
                    <div className="dashbordchart mixbar">
                      <AgTable
                        columnKeys={columnDefs?.field}
                        columnDefs={columnDefs3 ? columnDefs3 : []}
                        rowData={rowData3}
                        showTotalRow={true}
                        StyleClass={"excel_btn"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Last Transaction</p>
                    <div className="">
                      {/* <LastTransaction /> */}
                      <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                        <img
                          className="noData img-fluid"
                          src={NoData3}
                          alt="No data found"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Security Exposure</p>
                    <div className="dashbordchart mixbar">
                      <TreemapChart
                        data={securityExposureData}
                        colors={securityExposureColors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      Asset Allocation Performance-Since inception(XIRR){" "}
                    </p>
                    <div className="dashbordchart mixbar">
                      <ApppieChart
                        type={"BarChart"}
                        legendposition={"bottom"}
                        legenddisplay={true}
                        Chartdata={assetPerformance}
                        Chartlabels={assetPerformanceLabel}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      Portfolio Performance XIRR
                    </p>
                    <div className="dashbordchart mixbar">
                      <ApppieChart
                        type={"BarChart"}
                        legendposition={"bottom"}
                        legenddisplay={true}
                        Chartdata={portfolioValues}
                        Chartlabels={portfolioLabel}
                      />
                      {/* <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                        <img
                          className="noData img-fluid"
                          src={NoData3}
                          alt="No data found"
                        ></img>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Sector Exposure</p>
                    <div className="dashbordchart mixbar">
                      <TreemapChart
                        data={sectorExposure}
                        colors={sectorExposureColors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">
                      Mutual Fund AMC Exposure
                    </p>
                    <div className="dashbordchart mixbar">
                      <TreemapChart
                        data={amc}
                        colors={mutualFundAMCExposureColors}
                        
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Market Cap Exposure</p>
                    <div className="dashbordchart mixbar">
                      <TreemapChart
                        data={marketCap}
                        colors={marketCapExposureColors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Security Exposure</p>
                    <div className="dashbordchart mixbar">
                      <AgTable
                        columnKeys={columnDefs.field}
                        columnDefs={columnDefs4}
                        rowData={rowData4}
                        showTotalRow={true}
                        StyleClass={"excel_btn"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="dashbordchartname">
                        Upcoming Corporate Actions{" "}
                      </p>
                      <div className="relative">
                        <select className="border-0">
                          <option value="Select">Select Type</option>
                          <option value="Renewable & Maturities">New</option>
                          <option value="Non-Renewable & Maturities">
                            Old
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                      <img
                        className="noData img-fluid"
                        src={NoData3}
                        alt="No data found"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xl-6">
                <div className="card dashbordchartcard">
                  <div className="card-body dashbordchartTitle">
                    <p className="dashbordchartname">Systematic Transactions</p>
                    <div className="dashbordchart mixbar">
                      {/* <SystematicTransactions data={transactionData} /> */}
                      <div className="dashbordchart mixbar d-flex justify-content-center align-items-center">
                        <img
                          className="noData img-fluid"
                          src={NoData3}
                          alt="No data found"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
function AmountFormatterFunction(params) {
  return <AmountFormatter amount={params} />;
}
